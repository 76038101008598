import React, { useContext, useEffect } from 'react'
import Navbar from '../components/sections/Navbar'
import AboutMe from '../components/home-com/AboutMe'
import { AppContext } from '../context/AppContext'
import Progress from '../components/loader/Progress'
import FooterSM from '../components/sections/FooterSM'

const About = () => {
  const {isLoading} = useContext(AppContext);
  useEffect(() => {
    window.document.title = 'About'
  },[]);
  return (
    <div className='w-full bg-black flex flex-col items-center'>
        <Navbar/>
        <div className='w-full min-h-[90vh] flex flex-col justify-center items-center gap-6 mt-10 max-md:h-auto py-8'>
          <div className='flex flex-col items-center gap-0'>
            <h2 className='text-2xl font-bold uppercase text-blue-300'>About me</h2>
            <div className='flex items-center gap-1'>
              <div className='w-[30px] h-[2px] bg-blue-600'></div>
              <div className='cursive-text text-xl font-bold text-orange-500 max-sm:text-base'>who i am</div>
              <div className='w-[30px] h-[2px] bg-blue-600'></div>
            </div>
          </div>
          <AboutMe/>
        </div>

        <FooterSM/>

        {isLoading && (
          <div className='fixed left-0 top-0 w-full h-screen backdrop-blur bg-[#1111] flex justify-center items-center z-50'>
            <Progress/>
          </div>
        )}
    </div>
  )
}

export default About
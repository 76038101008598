import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import Spinner from "../loader/Spinner";
import { useNavigate } from "react-router-dom";

const Blogs = () => {
  const { isLoading, blogData, getBlogsDetails, currBlogPage, setCurrBlogPage } = useContext(AppContext);
  const [isActiveReadMoreBtn, setIsActiveReadMoreBtn] = useState([]);
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!blogData) {
      getBlogsDetails();
    }
  }, []);

  useEffect(() => {
    if (blogData && !hover) {
      const updatePagination = () => {
        setCurrBlogPage((prevPage) => {
          const nextPage = (prevPage + 1) % (blogData.length+1);
          return nextPage === 0 ? 1 : nextPage;
        });
      };
  
      const intervalId = setInterval(updatePagination, 5000);
  
      return () => clearInterval(intervalId);
    }
  }, [blogData, hover]);

  useEffect(() => {
    if (blogData) {
      const newArr = [];
      blogData[currBlogPage - 1].blogSection.forEach((blog) => {
        newArr.push({
          switch: true,
          title:
            blog.title.length > 60
              ? `${blog.title.substr(0, 60 )}...`
              : blog.title,
        });
      });
      setIsActiveReadMoreBtn(newArr);
    }
  }, [currBlogPage]);

  const textHandler = (originalTitle, index) => {
    setIsActiveReadMoreBtn((prevState) => {
      const currData = prevState.map((item, i) => ({
        ...item,
      }));

      if (currData[index]) {
        currData[index].switch = !currData[index].switch;

        if (currData[index].switch) {
          currData[index].title = `${originalTitle.substr(0, 60 )}...`;
        } else {
          currData[index].title = originalTitle;
        }
      }

      return currData;
    });
  };

  if (isLoading) {
    return (
      <div className="w-full flex justify-center items-center mt-4">
        <Spinner />
      </div>
    );
  }

  if (blogData && blogData[currBlogPage - 1]) {
    return (
      <div className={`w-full flex justify-center items-start gap-8 py-4 transition-all duration-300 ease-linear max-sm:p-4 max-sm:px-6`}>
        {blogData[currBlogPage - 1].blogSection.map((blog, index) => {
          return (
            <div
              key={index}
              className="w-[350px] flex flex-col rounded blog-bg max-sm:w-full"
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <div 
                className="w-full h-[200px] bg-[#222]"
                onClick={() => {
                navigate('/blog', { state: { scrollToId: blog._id } });
                }}
              >
                <img
                  src={blog.img}
                  alt="blog img error"
                  className="w-full h-full object-contain"
                />
              </div>
              <div className="w-full flex flex-col gap-4 p-4 max-sm:gap-2 max-sm:p-2">
                <div className="w-full flex items-center justify-start gap-1">
                  <p className="text-sm text-gray-400 max-sm:text-xs">{blog.dateTime}</p>
                  <p className="text-sm text-gray-400 max-sm:text-xs">/</p>
                  <p className="flex items-center gap-1">
                    <p className="text-md max-sm:text-xs text-black font-semibold">
                      {blog.likes.length}
                    </p>
                    <p className="text-sm max-sm:text-xs text-gray-400">likes</p>
                  </p>
                </div>
                <div className="text-black text-sm font-semibold text-justify max-sm:text-xs max-md:text-sm">
                  {isActiveReadMoreBtn[index]?.title}
                  {blog.title.length > 60 && (<span
                    className="text-blue-500 cursor-pointer"
                    onClick={() => textHandler(blog.title, index)}
                  >
                    {isActiveReadMoreBtn[index]?.switch ? "more" : " less"}
                  </span>)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  return <div className="text-white">SomeThing Went Wrong.</div>;
};

export default Blogs;

import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { LuMapPin } from "react-icons/lu";
import { HiOutlineMail } from "react-icons/hi";
import { FiPhone } from "react-icons/fi";
import { PiPaperPlaneRight } from "react-icons/pi";
import SmSpinner from '../loader/SmSpinner';
import Spinner from '../loader/Spinner'
import { toast } from 'react-toastify';
import { PiWarningOctagonBold } from "react-icons/pi";
import { MdVerified } from "react-icons/md";
import SocialMedia from './SocialMedia';

const ContactForm = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const {webData, area, city, getLocation, getWebDetails} = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [submitMessage, setSubmitMessage] = useState('');
    const [formData, setFormData] = useState({
        name:'',
        email:'',
        location:'',
        budget:undefined,
        subject:'',
        message:''
    });

    useEffect(() => {
        if(area && city){
            setFormData((prevState) => ({
                ...prevState,
                location: `${city}, ${area}`
            }))
        }
        else{
            getLocation();
        }
    },[area, city]);

    useEffect(() => {
        if(!webData){
            getWebDetails();
        }
    },[]);

    function inputHandler(event){
        setFormData((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    }

    if(!webData){
        return (
            <div>
                <Spinner/>
            </div>
        )
    }

    const submitHandler = async () => {
        try{
            setIsLoading(true);
            const url = `${baseUrl}/contact-form`;
            const response = await fetch(url, {
                method:'POST',
                headers:{
                    "Content-Type": "application/json"
                },
                body:JSON.stringify(formData)
            });
            const data = await response.json();
            if(data.success){
                setError('');
                setSubmitMessage(data.message);
                toast.success(data.message);
                setFormData({
                    name:'',
                    email:'',
                    budget:undefined,
                    subject:'',
                    message:''
                })
            }
            else{
                setSubmitMessage('');
                setError(data.message);
            }
        } catch(err){
            setSubmitMessage('');
            setError(err.message);
        } finally{
            setIsLoading(false);
        } 
    }

  return (
    <div className='w-full flex items-start justify-between gap-8 max-lg:gap-4 max-sm:flex-col'>
        <div className='w-full flex flex-col gap-4'>
            <div className='w-full flex flex-col gap-2 max-sm:gap-1'>
                <h1 className='text-2xl text-white font-extrabold max-sm:text-lg max-sm:text-center'>Let’s discuss your Project</h1>
                <p className='text-sm text-gray-400 font-semibold max-sm:text-xs'>Build Your Custom Web Application: Let’s discuss your unique project needs. Fill out the form, and I’ll get back to you to explore how we can bring your vision to life!</p>
            </div>

            <div className='w-full flex flex-col justify-start items-start gap-4 max-md:gap-2'>
                <div className='w-full flex items-center justify-start p-4 gap-4 bg-transparent transition duration-300 ease-in-out group hover:bg-[#333] rounded-md max-md:p-2 max-sm:gap-2'>
                    <div className='w-[50px] h-[50px] flex items-center justify-center bg-blue-200 text-blue-500 rounded group-hover:bg-blue-600 group-hover:text-white max-sm:w-[35px] max-sm:h-[35px]'><LuMapPin className='text-2xl'/></div>
                    <div className='flex flex-col items-start justify-center'>
                        <p className='text-base font-medium text-gray-400 max-lg:text-sm max-sm:text-xs'>Address</p>
                        <h4 className='text-lg font-semibold text-white max-lg:text-base max-sm:text-sm'>{webData?.address}</h4>
                    </div>
                </div>

                <div className='w-full flex items-center justify-start p-4 gap-4 bg-transparent transition duration-300 ease-in-out group hover:bg-[#333] rounded-md max-md:p-2 max-sm:gap-2'>
                    <div className='w-[50px] h-[50px] flex items-center justify-center bg-blue-200 text-blue-500 rounded group-hover:bg-blue-600 group-hover:text-white max-sm:w-[35px] max-sm:h-[35px]'><FiPhone className='text-2xl'/></div>
                    <div className='flex flex-col items-start justify-center'>
                        <p className='text-base font-medium text-gray-400 max-lg:text-sm max-sm:text-xs'>Phone</p>
                        <h4 className='text-lg font-semibold text-white max-lg:text-base max-sm:text-sm'>{webData?.mobile_no}</h4>
                    </div>
                </div>

                <div className='w-full flex items-center justify-start p-4 gap-4 bg-transparent transition duration-300 ease-in-out group hover:bg-[#333] rounded-md max-md:p-2 max-sm:gap-2'>
                    <div className='w-[50px] h-[50px] flex items-center justify-center bg-blue-200 text-blue-500 rounded group-hover:bg-blue-600 group-hover:text-white max-sm:w-[35px] max-sm:h-[35px]'><HiOutlineMail className='text-2xl'/></div>
                    <div className='flex flex-col items-start justify-center'>
                        <p className='text-base font-medium text-gray-400 max-lg:text-sm max-sm:text-xs'>Email</p>
                        <h4 className='text-lg font-semibold text-white max-lg:text-base max-sm:text-sm'>{webData?.email}</h4>
                    </div>
                </div>
            </div>

            <div className='mt-4 max-sm:mt-0'>
                <SocialMedia/>
            </div>
            
        </div>

        <div className='w-full flex flex-col items-start gap-4 max-md:gap-2'>
            <div className='w-full flex flex-col gap-0'>
                <div className="w-full relative flex items-center py-4 group max-md:py-2">
                    <label className="absolute top-1 max-md:top-[-3px] left-3 text-sm font-semibold text-gray-400 bg-[#222] px-1 transition-all group-focus-within:text-blue-600">
                        Name
                    </label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={inputHandler}
                        placeholder="Enter full name"
                        className="w-full py-2 px-4 border rounded-md border-gray-400 bg-transparent outline-none text-lg max-sm:text-base text-white font-semibold placeholder:text-gray-500 transition-all focus:border-blue-600"
                    />
                </div>

                <div className="w-full relative flex items-center py-4 group max-md:py-2">
                    <label className="absolute top-1 max-md:top-[-3px] left-3 text-sm font-semibold text-gray-400 bg-[#222] px-1 transition-all group-focus-within:text-blue-600">
                        Email<span className='text-red-500'>*</span>
                    </label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={inputHandler}
                        placeholder="Enter email"
                        className="w-full py-2 px-4 border rounded-md border-gray-400 bg-transparent outline-none text-lg max-sm:text-base text-white font-semibold placeholder:text-gray-500 transition-all focus:border-blue-600"
                    />
                </div>

                <div className="w-full relative flex items-center py-4 group group max-md:py-2">
                    <label className="absolute top-1 max-md:top-[-3px] left-3 text-sm font-semibold text-gray-400 bg-[#222] px-1 transition-all group-focus-within:text-blue-600">
                        Budget
                    </label>
                    <input
                        type="number"
                        name="budget"
                        value={formData.budget}
                        onChange={inputHandler}
                        placeholder="buget"
                        className="w-full py-2 px-4 border rounded-md border-gray-400 bg-transparent outline-none text-lg max-sm:text-base text-white font-semibold placeholder:text-gray-500 transition-all focus:border-blue-600"
                    />
                </div>

                <div className="w-full relative flex items-center py-4 group group max-md:py-2">
                    <label className="absolute top-1 max-md:top-[-3px] left-3 text-sm font-semibold text-gray-400 bg-[#222] px-1 transition-all group-focus-within:text-blue-600">
                        Subject<span className='text-red-500'>*</span>
                    </label>
                    <input
                        type="text"
                        name="subject"
                        value={formData.subject}
                        onChange={inputHandler}
                        placeholder="write topic"
                        className="w-full py-2 px-4 border rounded-md border-gray-400 bg-transparent outline-none text-lg max-sm:text-base text-white font-semibold placeholder:text-gray-500 transition-all focus:border-blue-600"
                    />
                </div>

                <div className="w-full relative flex items-center py-4 group group max-md:py-2">
                    <label className="absolute top-1 max-md:top-[-3px] left-3 text-sm font-semibold text-gray-400 bg-[#222] px-1 transition-all group-focus-within:text-blue-600">
                        Message<span className='text-red-500'>*</span>
                    </label>
                    <textarea
                        type="text"
                        name="message"
                        value={formData.message}
                        onChange={inputHandler}
                        placeholder="write message"
                        className="w-full py-2 px-4 border rounded-md border-gray-400 bg-transparent outline-none text-lg max-sm:text-base text-white font-semibold placeholder:text-gray-500 transition-all focus:border-blue-600"
                    />
                </div>
            </div>

            {error !== '' && (
                <div className='w-full flex items-center justify-start gap-1 text-sm max-sm:text-xs font-semibold text-red-500 border border-red-500 py-1 px-2'><PiWarningOctagonBold className='text-lg max-sm:text-base text-yellow-500'/> {error}</div>
            )}

            {submitMessage !== '' && (
                <div className='w-full flex items-center justify-start gap-1 text-sm max-sm:text-xs font-semibold text-green-500 border border-green-500 py-1 px-2'><MdVerified className='text-lg max-sm:text-base text-green-500'/> {submitMessage}</div>
            )}

            <button 
                onClick={submitHandler}
                className='text-lg max-sm:text-base font-bold text-white border-2 border-blue-600 bg-blue-600 py-1 px-4 flex justify-center items-center gap-2 rounded transition duration-300 ease-in hover:bg-transparent hover:text-blue-600'
            >
                Submit {isLoading ? (<SmSpinner/>) : (<PiPaperPlaneRight/>)}
            </button>
        </div>
    </div>
  )
}

export default ContactForm
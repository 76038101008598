import React, { useContext, useState } from "react";
import { FaHeart } from "react-icons/fa6";
import { AppContext } from "../../context/AppContext";
import { toast } from "react-toastify";
import { RiDashboardFill } from "react-icons/ri";
import { Link, useNavigate } from 'react-router-dom';
import { CgMenuRightAlt } from "react-icons/cg";
import { RiCloseLargeLine } from "react-icons/ri";

const Buttons = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const {isLoggedIn, likeCounts, setLikeCounts, isLikedProfile, profileLike, setIsLikedProfile, formData, setIsActiveAskEmail, validateEmail, setLikeTag, isActiveMenuBtn, setIsActiveMenuBtn } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);

  function likeHandler(){
    if(validateEmail(formData.email)){
      if(isLikedProfile){
        profileDisLike();
      } else{
        profileLike();
      }
    }
    else{
      setLikeTag({tag:'like profile', project_id:'', index:''});
      setIsActiveAskEmail(true);
      toast.warn('Please enter your email');
      return;
    }
  }

  const profileDisLike = async () => {
    try {
      setIsLoading(true);
      const url = `${baseUrl}/dislike-profile`;
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: formData.email }),
      });
      const data = await response.json();
      if (data.success) {
        setLikeCounts(data.count);
        setIsLikedProfile(false);
        toast.success(data.message);
      } else {
        toast.warning(data.message);
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="flex items-center gap-2">
      {isActiveMenuBtn ? 
      (
        <RiCloseLargeLine
          onClick={() => setIsActiveMenuBtn(false)}
          className="text-3xl text-gray-200 max-sm:text-xl"
        />
      ) : 
      (
        <CgMenuRightAlt
          onClick={() => setIsActiveMenuBtn(true)}
          className="text-4xl text-gray-200 md:hidden max-sm:text-xl"
        />
      )}

      <div 
        className={`flex items-center gap-2 py-1 px-3 border rounded-lg cursor-pointer ${isLikedProfile ? 'border-red-500 bg-red-500 text-white' : 'border-white text-white bg-transparent transition duration-200 ease-in hover:border-red-500 hover:text-red-500'} max-sm:px-2 max-sm:gap-1`}
        onClick={likeHandler}
      >
        <FaHeart className={`text-xl ${!isLikedProfile && 'text-red-300'} max-sm:text-base`} />
        <div className="text-lg font-bold max-sm:text-sm">
          {likeCounts > 0 && likeCounts}
        </div>
      </div>

      {isLoggedIn && (<RiDashboardFill className='text-3xl text-gray-200 cursor-pointer transition duration-300 ease-in hover:text-blue-500 max-sm:text-xl' onClick={() => navigate('/dashboard')}/>)}
      <Link to={'https://buymeacoffee.com/neeraj_webdev'} target="_blank">
        <img src="https://res.cloudinary.com/do1xweis7/image/upload/v1724661975/bmc-logo_mymhmk.png" alt="Buy Me A Coffee" className="w-[40px] max-sm:w-[30px]"/>
      </Link>
    </div>
  );
};

export default Buttons;

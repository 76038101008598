import React, { useContext, useEffect, useState } from "react";
import Navbar from "../components/sections/Navbar";
import { MdLogout } from "react-icons/md";
import { AppContext } from "../context/AppContext";
import { UserContext } from '../context/UserContext';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MainMenu from "../components/dashboard/MainMenu";
import Projects from "../components/dashboard/Projects";
import AddBlog from "../components/dashboard/AddBlog";
import AddTestimonial from "../components/dashboard/AddTestimonial";
import UserDetails from '../components/dashboard/UserDetails';
import AddProject from '../components/pop-up/AddProject';
import { IoHome } from "react-icons/io5";
import PostBlog from "../components/pop-up/PostBlog";

const Dashboard = () => {
  const { setIsLoggedIn } = useContext(AppContext);
  const {isActiveAddProjectForm, isActiveAddBlogForm} = useContext(UserContext);
  const [currentSection, setCurrentSection] = useState("dashboard");
  const navigate = useNavigate();

  useEffect(() => {
    window.document.title = 'Dashboard'
  },[]);

  const handleLogout = () => {
    localStorage.removeItem("NPToken");
    setIsLoggedIn(false);
    navigate("/login");
    toast.warning("Logged out successfully");
  };

  return (
    <div className="w-full h-screen flex items-center justify-center bg-black">
      <Navbar />
      <div className="w-full h-full flex items-center pt-[80px] text-white">
        <div className="w-[300px] h-full flex flex-col items-start border border-gray-600">
          <h2 onClick={() => setCurrentSection('dashboard')} className="w-full flex items-center justify-center gap-2 text-center text-2xl font-extrabold text-blue-500 py-2 cursor-pointer">
            <IoHome className="text-yellow-500" />
            Dashboard
          </h2>

          <div onClick={() => setCurrentSection('account')} className={`w-full text-lg font-semibold text-gray-300 py-1 px-4 border-y cursor-pointer transition duration-300 ease-in border-[#333] ${currentSection === 'account' ? 'bg-[#222]' : 'hover:text-white hover:bg-[#222]'}`}>
            Account
          </div>
          <div onClick={() => setCurrentSection('projects')} className={`w-full text-lg font-semibold text-gray-300 py-1 px-4 border-y cursor-pointer transition duration-300 ease-in border-[#333] ${currentSection === 'projects' ? 'bg-[#222]' : 'hover:text-white hover:bg-[#222]'}`}>
            Projects
          </div>
          <div onClick={() => setCurrentSection('addblog')} className={`w-full text-lg font-semibold text-gray-300 py-1 px-4 border-y cursor-pointer transition duration-300 ease-in border-[#333] ${currentSection === 'addblog' ? 'bg-[#222]' : 'hover:text-white hover:bg-[#222]'}`}>
            Blogs
          </div>
          <div onClick={() => setCurrentSection('testimonial')} className={`w-full text-lg font-semibold text-gray-300 py-1 px-4 border-y cursor-pointer transition duration-300 ease-in border-[#333] ${currentSection === 'testimonial' ? 'bg-[#222]' : 'hover:text-white hover:bg-[#222]'}`}>
            Add Testimonial
          </div>
          <div
            className="w-full flex justify-between items-center text-lg font-semibold text-red-500 py-1 px-4 border-y border-[#333] cursor-pointer transition duration-300 ease-in hover:text-white hover:bg-red-500"
            onClick={handleLogout}
          >
            Log Out <MdLogout />
          </div>
        </div>

        <div className="w-full h-full bg-[#222] border border-gray-600 py-6 px-8">
          {currentSection === "dashboard" && <MainMenu />}
          {currentSection === "account" && <UserDetails />}
          {currentSection === "projects" && <Projects />}
          {currentSection === "addblog" && <AddBlog />}
          {currentSection === "testimonial" && <AddTestimonial />}
        </div>
      </div>

      {isActiveAddProjectForm && (<div className='w-full h-screen fixed top-0 left-0 flex justify-center items-start backdrop-blur z-50'><AddProject/></div>)}
      {isActiveAddBlogForm && (<div className='w-full h-screen fixed top-0 left-0 flex justify-center items-start backdrop-blur z-50'><PostBlog/></div>)}
    </div>
  );
};

export default Dashboard;

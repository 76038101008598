import React, { useContext, useEffect } from 'react';
import Navbar from '../components/sections/Navbar';
import ContactForm from '../components/home-com/ContactForm';
import Footer2 from '../components/sections/Footer2';
import { AppContext } from '../context/AppContext';
import Progress from '../components/loader/Progress'

const Conatct = () => {
  const {isLoading} = useContext(AppContext);

  useEffect(() => {
    window.document.title = 'Contact me'
  },[]);
  
  return (
    <div className='w-full flex flex-col bg-black relative'>
        <Navbar/>
        <div className='w-full flex flex-col items-center gap-6 py-6 mt-16 max-md:gap-4 max-sm:gap-4'>
          <div className='flex flex-col items-center'>
            <h2 className='text-2xl font-bold uppercase text-blue-300'>Contact me</h2>
            <div className='flex items-center gap-1'>
              <div className='w-[30px] h-[2px] bg-blue-600'></div>
              <div className='cursive-text text-xl font-bold text-orange-500 max-sm:text-base'>get in touch</div>
              <div className='w-[30px] h-[2px] bg-blue-600'></div>
            </div>
          </div>

          <div className='w-[90%] bg-[#222] py-8 px-8 rounded-xl max-lg:w-[95%] max-lg:px-4 max-md:w-[98%] max-sm:p-2'><ContactForm/></div>
        </div>
        <Footer2/>

        {isLoading && (
          <div className='fixed left-0 top-0 w-full h-screen backdrop-blur bg-[#1111] flex justify-center items-center z-50'>
            <Progress/>
          </div>
        )}
    </div>
  )
}

export default Conatct
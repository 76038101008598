import React, { useContext, useEffect } from 'react'
import { UserContext } from '../../context/UserContext'
import Spinner from '../loader/Spinner'
import { LuFolderHeart } from "react-icons/lu";
import { FaUsersViewfinder } from "react-icons/fa6";
import { MdWorkHistory } from "react-icons/md";
import { FaBlog } from "react-icons/fa";

const MainMenu = () => {
    const {data, getAdminDetails, isLoading} = useContext(UserContext);

    useEffect(() => {
        getAdminDetails();
    },[])

    function getDateFromString(dateTimeString) {
        const date = new Date(dateTimeString);
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based, so +1
        const day = String(date.getUTCDate()).padStart(2, '0');
        
        return `${year}-${month}-${day}`;
    }

    function getTimeFromString(dateTimeString) {
        const date = new Date(dateTimeString);
        return date.toLocaleTimeString('en-IN', { timeZone: 'Asia/Kolkata', hour12: false });
    }       
    

    if(isLoading){
        return (
            <div className='w-full h-full flex justify-center items-center'><Spinner/></div>
        )
    }
  return (
    <div className='w-full flex flex-col items-start gap-8'>
        <div className='w-full flex items-center justify-start gap-4'>
            <button className={`text-md font-semibold text-gray-400 bg-[#111] py-1 px-3 border border-transparent rounded-lg`}>last month</button>
            <button className={`text-md font-semibold text-gray-400 bg-[#111] py-1 px-3 border border-transparent rounded-lg`}>7-days</button>
            <button className={`text-md font-semibold text-gray-400 bg-[#111] py-1 px-3 border border-transparent rounded-lg`}>today</button>
        </div>

        <div className='w-full flex items-center justify-around'>
            {(data && data.likes) &&
            (<div className='flex flex-col items-center justify-center gap-1 border-2 border-red-500 bg-red-300 py-3 px-5 rounded-lg'>
                <p className='text-2xl text-black font-extrabold'>{data.likes.length}</p>
                <h2 className='text-lg text-gray-600 font-semibold'>total likes</h2>
            </div>)}

            {(data && data.views) && (<div className='flex flex-col items-center justify-center gap-1 border-2 border-yellow-500 bg-yellow-300 py-3 px-5 rounded-lg'>
                <p className='text-2xl text-black font-extrabold'>{data.views.length}</p>
                <h2 className='text-lg text-gray-600 font-semibold'>total views</h2>
            </div>)}

            {(data && data.projects) && <div className='flex flex-col items-center justify-center gap-1 border-2 border-green-500 bg-green-300 py-3 px-5 rounded-lg'>
                <p className='text-2xl text-black font-extrabold'>{data.projects.length}</p>
                <h2 className='text-lg text-gray-600 font-semibold'>total projects</h2>
            </div>}

            {(data && data.blogs) && (<div className='flex flex-col items-center justify-center gap-1 border-2 border-orange-500 bg-orange-300 py-3 px-5 rounded-lg'>
                <p className='text-2xl text-black font-extrabold'>{data.blogs.length}</p>
                <h2 className='text-lg text-gray-600 font-semibold'>total blogs</h2>
            </div>)}

            <div className='flex flex-col items-center justify-center gap-1 border-2 border-blue-500 bg-blue-300 py-3 px-5 rounded-lg'>
                <p className='text-2xl text-black font-extrabold'>500$</p>
                <h2 className='text-lg text-gray-600 font-semibold'>total earn</h2>
            </div>
        </div>

        <div className='w-full flex flex-col gap-2'>
            <caption className='w-full text-left flex items-center justify-start gap-2 text-xl font-semibold'><LuFolderHeart className='text-xl text-red-400'/>Likes</caption>
            <table className="w-full">
                <thead>
                    <tr className="bg-red-400 text-black text-left border border-red-500">
                        <th className="px-3 py-2 text-lg font-bold border border-[#444]">Id</th>
                        <th className="px-3 py-2 text-lg font-bold border border-[#444]">Name</th>
                        <th className="px-3 py-2 text-lg font-bold border border-[#444]">Email</th>
                        <th className="px-3 py-2 text-lg font-bold border border-[#444]">Date</th>
                        <th className="px-3 py-2 text-lg font-bold border border-[#444]">Time</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (data && data.likes) && 
                        data.likes.map((like, index) => {
                            return (
                                <tr key={index} className='bg-red-300 text-gray-700 border border-[#999]'>
                                    <td className="px-3 py-2 border border-[#999]">{like._id}</td>
                                    <td className="px-3 py-2 border border-[#999]">{like.username}</td>
                                    <td className="px-3 py-2 border border-[#999]">{like.email}</td>
                                    <td className="px-3 py-2 border border-[#999]">{getDateFromString(like.likeAt)}</td>
                                    <td className="px-3 py-2 border border-[#999]">{getTimeFromString(like.likeAt)}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>

        <div className='w-full flex flex-col gap-2'>
            <caption className='w-full text-left flex items-center justify-start gap-2 text-xl font-semibold'><FaUsersViewfinder className='text-xl text-yellow-400'/>Views</caption>
            <table className="w-full">
                <thead>
                    <tr className="bg-yellow-400 text-black text-left border border-yellow-500">
                        <th className="px-3 py-2 text-lg font-bold border border-[#444]">Id</th>
                        <th className="px-3 py-2 text-lg font-bold border border-[#444]">Location</th>
                        <th className="px-3 py-2 text-lg font-bold border border-[#444]">Date</th>
                        <th className="px-3 py-2 text-lg font-bold border border-[#444]">Time</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (data && data.views) && 
                        data.views.map((view, index) => {
                            return (
                                <tr key={index} className='bg-yellow-300 text-gray-700 border border-[#999]'>
                                    <td className="px-3 py-2 border border-[#999]">{view._id}</td>
                                    <td className="px-3 py-2 border border-[#999]">{view.location}</td>
                                    <td className="px-3 py-2 border border-[#999]">{getDateFromString(view.ViewedAt)}</td>
                                    <td className="px-3 py-2 border border-[#999]">{getTimeFromString(view.ViewedAt)}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>

        <div className='w-full flex flex-col gap-2'>
            <caption className='w-full text-left flex items-center justify-start gap-2 text-xl font-semibold'><MdWorkHistory className='text-xl text-green-400'/>Projects</caption>
            <table className="w-full">
                <thead>
                    <tr className="bg-green-400 text-black text-left border border-green-500">
                        <th className="px-3 py-2 text-lg font-bold border border-[#444]">Id</th>
                        <th className="px-3 py-2 text-lg font-bold border border-[#444]">Name</th>
                        <th className="px-3 py-2 text-lg font-bold border border-[#444]">Title</th>
                        <th className="px-3 py-2 text-lg font-bold border border-[#444]">Date</th>
                        <th className="px-3 py-2 text-lg font-bold border border-[#444]">Time</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (data && data.projects) && 
                        data.projects.map((project, index) => {
                            return (
                                <tr key={index} className='bg-green-300 text-gray-700 border border-[#999]'>
                                    <td className="px-3 py-2 border border-[#999]">{project._id}</td>
                                    <td className="px-3 py-2 border border-[#999]">{project.name}</td>
                                    <td className="px-3 py-2 border border-[#999]">{project.title}</td>
                                    <td className="px-3 py-2 border border-[#999]">{getDateFromString(project.createdAt)}</td>
                                    <td className="px-3 py-2 border border-[#999]">{getTimeFromString(project.createdAt)}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>

        <div className='w-full flex flex-col gap-2'>
            <caption className='w-full text-left flex items-center justify-start gap-2 text-xl font-semibold'><FaBlog className='text-xl text-orange-400'/>Blogs</caption>
            <table className="w-full">
                <thead>
                    <tr className="bg-orange-400 text-black text-left border border-orange-500">
                        <th className="px-3 py-2 text-lg font-bold border border-[#444]">Id</th>
                        <th className="px-3 py-2 text-lg font-bold border border-[#444] w-[300px]">Title</th>
                        <th className="px-3 py-2 text-lg font-bold border border-[#444]">Likes</th>
                        <th className="px-3 py-2 text-lg font-bold border border-[#444]">Comments</th>
                        <th className="px-3 py-2 text-lg font-bold border border-[#444]">Date</th>
                        <th className="px-3 py-2 text-lg font-bold border border-[#444]">Time</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (data && data.blogs) && 
                        data.blogs.map((blog, index) => {
                            return (
                                <tr key={index} className='bg-orange-300 text-gray-700 border border-[#999]'>
                                    <td className="px-3 py-2 border border-[#999]">{blog._id}</td>
                                    <td className="px-3 py-2 border border-[#999]">{blog.title}</td>
                                    <td className="px-3 py-2 border border-[#999]">{blog.likes.length}</td>
                                    <td className="px-3 py-2 border border-[#999]">{blog.comments.length}</td>
                                    <td className="px-3 py-2 border border-[#999]">{getDateFromString(blog.createdAt)}</td>
                                    <td className="px-3 py-2 border border-[#999]">{getTimeFromString(blog.createdAt)}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>

    </div>
  )
}

export default MainMenu
import React from 'react'

const ThreeDScene = () => {
  return (
    <div className='w-[400px] max-sm:w-[80%]'>
        <img src='https://res.cloudinary.com/do1xweis7/image/upload/v1723711613/sde_cpztr6.png' alt='sde' className='w-full object-cover max-md:hidden'/>
        <img src='https://res.cloudinary.com/do1xweis7/image/upload/v1724679411/3d-se-img_r4cjo8.png' alt='sde' className='w-full object-cover md:hidden'/>
    </div>
  )
}

export default ThreeDScene
import React from 'react';
import { FaCode, FaCrop, FaBug } from "react-icons/fa6";

const ServicesCard = () => {
  return (
    <div className='w-full flex justify-center gap-8 px-8 py-7 text-white flex-wrap max-sm:gap-6 max-sm:p-4'>
        <div className='w-[350px] flex flex-col items-start bg-[#222] p-4 gap-4 rounded-xl transition duration-300 ease-in transform hover:translate-y-[-8px] hover:bg-rose-600 max-sm:w-full max-sm:gap-2'>
            <FaCode className='text-6xl max-md:text-3xl max-lg:text-4xl'/>
            <h2 className='text-2xl font-bold text-white max-md:text-xl'>Web Design</h2>
            <p className='text-base text-gray-300 font-medium max-md:text-sm'>Craft responsive, visually engaging, and user-friendly websites, ensuring optimal performance across all devices and enhancing user experience.</p>
        </div>
        <div className='w-[350px] flex flex-col items-start bg-[#222] p-4 gap-4 rounded-xl transition duration-300 ease-in transform hover:translate-y-[-8px] hover:bg-rose-600 max-sm:w-full max-sm:gap-2'>
            <FaCrop className='text-6xl max-md:text-3xl max-lg:text-4xl'/>
            <h2 className='text-2xl font-bold text-white max-md:text-xl'>UI/UX Design</h2>
            <p className='text-base text-gray-300 font-medium max-md:text-sm'>Design intuitive and aesthetically pleasing interfaces, focusing on user behavior and experience to create seamless digital journeys.</p>
        </div>
        <div className='w-[350px] flex flex-col items-start bg-[#222] p-4 gap-4 rounded-xl transition duration-300 ease-in transform hover:translate-y-[-8px] hover:bg-rose-600 max-sm:w-full max-sm:gap-2'>
            <FaBug className='text-6xl max-md:text-3xl max-lg:text-4xl'/>
            <h2 className='text-2xl font-bold text-white max-md:text-xl'>Debugging</h2>
            <p className='text-base text-gray-300 font-medium max-md:text-sm'>Analyze and resolve complex code issues, ensuring clean, efficient, and error-free application functionality.</p>
        </div>
    </div>
  )
}

export default ServicesCard;
import React, { useContext, useEffect, useState } from "react";
import Navbar from "../components/sections/Navbar";
import { AppContext } from "../context/AppContext";
import Progress from "../components/loader/Progress";
import FooterSM from "../components/sections/FooterSM";
import { BiLike, BiSolidLike } from "react-icons/bi";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineComment } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Blog = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const {
    isLoading,
    descInfo,
    setDescInfo,
    blogPageData,
    getBlogsPage,
    blogLike,
    validateEmail,
    formData,
    setLikeTag,
    setIsActiveAskEmail,
    setIsActiveComment
  } = useContext(AppContext);
  const [currentPage, setCurrentPage] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = `${window.location.origin}${location.pathname}`;

  useEffect(() => {
    window.document.title = 'My Blogs'
  },[]);

  useEffect(() => {
    if (blogPageData) {
      const descArr = blogPageData.blogs[currentPage].blogSection.map(
        (item) => ({
          isActive: false,
          desc:
            item.description.length > 100
              ? `${item.description.substring(0, 100)}`
              : item.description,
          isLike: false,
          commetsCount: item.comments.length,
        })
      );

      setDescInfo(descArr);
    }
  }, [blogPageData, currentPage]);

  useEffect(() => {
    if (!blogPageData) {
      getBlogsPage();
    }
  }, []);

  const descChangeHandler = (index, fullDesc) => {
    setDescInfo((prevDesc) => {
      return prevDesc.map((item, i) => {
        if (i === index) {
          const isActive = !item.isActive;
          const desc = isActive ? fullDesc : `${fullDesc.substring(0, 100)}`;
          return { ...item, isActive, desc };
        }
        return item;
      });
    });
  };

  const handleShare = (blogTitle) => {
    const message = `Check out this blog: ${blogTitle} - ${currentUrl}`;
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, "_blank");
  };

  const likeHandler = (index, blogId) => {
    if (validateEmail(formData.email)) {
      if (descInfo[index].isLike) {
        blogDisike(blogId, index);
      } else {
        blogLike(blogId, index);
      }
    } else {
      setLikeTag({ tag: "like blog", blog_id: blogId, index: index });
      setIsActiveAskEmail(true);
      return;
    }
  };

  const blogDisike = async (blogId, index) => {
    setDescInfo((prevDesc) => {
      return prevDesc.map((item, i) => {
        if (i === index) {
          const isLike = false;
          return { ...item, isLike };
        }
        return item;
      });
    });

    try {
      const url = `${baseUrl}/blog/dislike`;
      const bodyData = {
        email: formData.email,
        username: formData.username,
        blog_id: blogId,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
      });

      const data = await response.json();

      if (data.success) {
        setDescInfo((prevDesc) => {
          return prevDesc.map((item, i) => {
            if (i === index) {
              const isLike = false;
              return { ...item, isLike };
            }
            return item;
          });
        });
        toast.success(data.message);
      } else {
        setDescInfo((prevDesc) => {
          return prevDesc.map((item, i) => {
            if (i === index) {
              const isLike = true;
              return { ...item, isLike };
            }
            return item;
          });
        });
        toast.warning(data.message);
      }
    } catch (err) {
      setDescInfo((prevDesc) => {
        return prevDesc.map((item, i) => {
          if (i === index) {
            const isLike = true;
            return { ...item, isLike };
          }
          return item;
        });
      });
      toast.error(err.message);
    }
  };

  const commentHandler = async(blogId, index) => {
    if (validateEmail(formData.email)) {
      setLikeTag({ tag: "comment", blog_id:blogId, index:index });
      setIsActiveComment(true);
    } 
    else {
      setLikeTag({ tag: "comment", blog_id:blogId });
      setIsActiveAskEmail(true);
      return;
    }
  }

  useEffect(() => {
    // Scroll after page renders if scrollToId exists in state
    if (location.state?.scrollToId) {
      const element = document.getElementById(location.state.scrollToId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <div className="w-full bg-black flex flex-col items-center">
      <Navbar />
      <div className='flex flex-col items-center gap-0 md:hidden mt-20'>
        <h2 className='text-2xl font-bold uppercase text-blue-300'>Latest Blogs</h2>
        <div className='flex items-center gap-1'>
          <div className='w-[30px] h-[2px] bg-blue-600'></div>
          <div className='cursive-text text-xl font-bold text-orange-500 max-sm:text-base lowercase'>Explore My Story</div>
          <div className='w-[30px] h-[2px] bg-blue-600'></div>
        </div>
      </div>

      <div className="w-full flex items-start justify-center py-8 mt-12 max-md:mt-0">
        <div className="w-[300px] max-lg:w-[200px] max-md:hidden max-lg:left-2 fixed left-8 bg-[#222] flex flex-col items-center rounded-xl py-6 px-4 gap-4 max-lg:gap-2 max-lg:p-3">
          <div className="w-full flex items-center justify-start pb-4">
            <button
              onClick={() => navigate(-1)}
              className="text-base max-lg:text-sm font-bold text-white border flex items-center gap-1 py-1 px-2 rounded-md transition duration-300 ease-in hover:bg-gray-300 hover:text-black"
            >
              <IoIosArrowBack className="text-lg" /> Back
            </button>
          </div>
          <div className="w-[100px] h-[100px] rounded-full border-2 border-blue-600 max-lg:w-[60px] max-lg:h-[60px]">
            <img
              src={blogPageData?.admin.img}
              alt="admin img"
              className="w-full h-full"
            />
          </div>
          <h3 className="text-xl font-bold text-gray-100 uppercase max-lg:text-base">
            {blogPageData?.admin.name}
          </h3>
          <div className="text-lg font-medium text-lime-600 text-center max-lg:text-sm">Stay updated with our blogs</div>
        </div>

        <div className="w-[500px] max-md:w-full max-lg:w-[350px] flex flex-col items-center gap-4 max-md:px-4 max-sm:px-1 max-sm:gap-2">
          {blogPageData?.blogs[currentPage].blogSection.map((blog, index) => {
            return (
              <div id={blog._id} key={index} className="w-full flex flex-col items-start bg-[#222] border-2 border-[#222] rounded-md">
                <div className="w-full flex flex-col p-4 max-sm:p-2">
                  <div className="text-sm font-medium text-gray-400 max-sm:text-xs">
                    {blog.dateTime}
                  </div>
                  <div className="text-lg font-bold text-white max-sm:text-sm">
                    {blog.title}
                  </div>
                  <div className="w-full flex flex-col items-start">
                    <div className="text-sm font-normal text-gray-400 max-md:text-justify max-sm:text-xs">
                      {descInfo[index]?.desc}
                      <span
                        className="text-blue-500 cursor-pointer font-semibold"
                        onClick={() =>
                          descChangeHandler(index, blog.description)
                        }
                      >
                        {descInfo[index]?.isActive ? " less" : "...more"}
                      </span>
                    </div>
                    <div className="w-full flex items-center gap-x-2 max-sm:gap-x-1 gap-y-0 flex-wrap">
                      {blog.hashTags.map((tag, index) => {
                        return (
                          <p key={index} className="text-orange-500 font-semibold text-sm max-sm:text-xs hover:underline cursor-pointer">
                            {tag}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <img
                  src={blog.img}
                  alt="blog image"
                  className="w-full h-full object-cover"
                />

                <div className="w-full flex flex-col items-start px-4 py-2 max-sm:p-1">
                  <div className="w-full flex justify-between items-center">
                    {blog.likes.length > 0 && (
                      <div className="flex items-center pb-1">
                        {descInfo[index]?.isLike ? (
                          <div className="flex items-center gap-1">
                            <div className="w-[17px] h-[17px] max-sm:w-[14px] max-sm:h-[14px] rounded-full bg-blue-500 text-white flex items-center justify-center">
                              <BiSolidLike className="text-xs"/>
                            </div>
                            <span className="text-xs text-gray-400">you {blog.likes.length > 0 && (<span>and {blog.likes.length} others</span>)}</span>
                          </div>
                        ) : (
                          <div className="text-xs text-gray-400">{blog.likes.length} likes</div>
                        )}
                      </div>
                    )}
                    {descInfo[index]?.commetsCount > 0 && (
                      <div className="text-gray-400 text-xs">{descInfo[index]?.commetsCount} comments</div>
                    )}
                  </div>

                  <div className="w-full h-[1px] bg-slate-600"></div>

                  <div className="w-full flex justify-between pt-1">
                    <button
                      onClick={() => likeHandler(index, blog._id)}
                      className={`flex items-center gap-1 text-base max-sm:text-sm font-medium  ${
                        descInfo[index]?.isLike
                          ? "text-blue-500"
                          : "text-white transition duration-300 ease-in hover:text-blue-500"
                      }`}
                    >
                      {descInfo[index]?.isLike ? (
                        <BiSolidLike className="text-lg max-sm:text-base" />
                      ) : (
                        <BiLike className="text-lg max-sm:text-base" />
                      )}{" "}
                      Like
                    </button>

                    <button
                      onClick={() => handleShare(blog.title)}
                      className="flex items-center gap-1 text-base max-sm:text-sm font-medium text-white  transition duration-300 ease-in hover:text-gray-300"
                    >
                      <FaWhatsapp className="text-lg max-sm:text-base" /> Share
                    </button>

                    <button onClick={() => commentHandler(blog._id, index)} className="flex items-center gap-1 text-base max-sm:text-sm font-medium text-white  transition duration-300 ease-in hover:text-gray-300">
                      <MdOutlineComment className="text-lg max-sm:text-base" /> Comment
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="w-full flex items-center justify-between gap-2 text-white">
            {currentPage !== 0 && (
              <button
                onClick={() => {
                  setCurrentPage(currentPage - 1);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className="border text-base max-sm:text-sm font-semibold py-1 px-2 rounded-md text-gray-300 border-gray-400 transition duration-300 ease-in hover:bg-gray-400 hover:text-black"
              >
                Prev
              </button>
            )}
            <div className="flex items-center justify-center gap-2">
              {blogPageData?.blogs.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      setCurrentPage(item.page - 1);
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                    className={`w-[35px] h-[35px] max-sm:w-[25px] max-sm:h-[25px] rounded-full border border-white flex justify-center items-center cursor-pointer ${
                      currentPage === item.page - 1
                        ? "bg-white text-black"
                        : "bg-transparent text-gray-400 transition duration-300 ease-in hover:bg-gray-300 hover:text-gray-600"
                    }`}
                  >
                    <p className="text-lg font-bold max-sm:text-sm">{item.page}</p>
                  </div>
                );
              })}
            </div>
            {currentPage !== blogPageData?.blogs.length - 1 && (
              <button
                onClick={() => {
                  setCurrentPage(currentPage + 1);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className="border text-base max-sm:text-sm font-semibold py-1 px-2 rounded-md text-gray-300 border-gray-400 transition duration-300 ease-in hover:bg-gray-400 hover:text-black"
              >
                Next
              </button>
            )}
          </div>
        </div>

        <div className="w-[250px] max-md:hidden max-lg:w-[200px] max-lg:right-2 fixed right-8 bg-[#222] flex flex-col items-center rounded-xl py-6 px-4 gap-4 max-lg:p-3 max-lg:gap-2">
          <h3 className="text-xl font-bold text-gray-100 max-lg:text-base">My Blogs</h3>
          {formData.username !== "" && (
            <div className="flex flex-col items-center text-lg text-white">
              <p className="text-base font-semibold text-gray-400 max-lg:text-sm">Hi 👋</p>
              <p className="text-lg font-bold text-white max-lg:text-base">{formData.username}</p>
            </div>
          )}
        </div>
      </div>

      <FooterSM />

      {isLoading && (
        <div className="fixed left-0 top-0 w-full h-screen backdrop-blur bg-[#1111] flex justify-center items-center z-50">
          <Progress />
        </div>
      )}
    </div>
  );
};

export default Blog;

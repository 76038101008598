import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/AppContext';
import { IoClose } from "react-icons/io5";
import { GoCommentDiscussion } from "react-icons/go";
import { userData } from 'three/webgpu';
import { toast } from 'react-toastify';
import SmSpinner from '../loader/SmSpinner';
import Spinner from '../loader/Spinner';

const Comment = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const {setIsActiveComment, formData, likeTag, setDescInfo} = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [commentsData, setCommentData] = useState(null);
    const [inputData, setInputdata] = useState({
        message:''
    });

    useEffect(() => {
        if(!commentsData){
            getBlogComments();
        }
    },[]);

    function inputHandler(event){
        setInputdata((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }))
    }

    const getBlogComments = async() => {
        try{
            setLoading(true);
            const url = `${baseUrl}/get-blog/comment`;
            const response = await fetch(url, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    blog_id: likeTag.blog_id
                })
            });
            const data = await response.json();
            if(data.success){
                setCommentData(data.comments);
            }
            else{
                setCommentData(null);
            }
        } catch(err){
            toast.error(err.message);
        } finally{
            setLoading(false);
        }
    } 

    const commentHandler = async () => {
        try{
            setIsLoading(true);
            const url = `${baseUrl}/blog/comment-add`;
            const response = await fetch(url, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    username: formData.username,
                    email: userData.email,
                    blog_id: likeTag.blog_id,
                    message: inputData.message
                })
            });
            const data = await response.json();
            if(data.success){
                setDescInfo((prevDesc) => {
                    return prevDesc.map((item, i) => {
                      if (i === likeTag.index) {
                        const commetsCount = data.comments.length;
                        return { ...item, commetsCount };
                      }
                      return item;
                    });
                  });
                setCommentData(data.comments);
                setInputdata({message:''})
                toast.success(data.message);
            }
            else{
                toast.warning(data.message);
            }
        } catch(err){
            toast.error(err.message);
        } finally{
            setIsLoading(false);
        }
    }
    const getUserImg = (name) => {
        return `https://api.dicebear.com/5.x/initials/svg?seed=${name}`;
    }
    
  return (
    <div className='w-[450px] flex flex-col items-center gap-4 bg-[#222] py-4 px-6 rounded-lg max-sm:rounded-b-none'>
        <div className='w-full flex items-center justify-between'>
            <h2 className="text-2xl font-bold text-blue-500 text-style capitalize max-sm:text-lg">Comments</h2>
            <div
                className="w-[40px] h-[40px] max-sm:w-[30px] max-sm:h-[30px] flex justify-center items-center rounded-full bg-blue-200 cursor-pointer transition duration-300 ease-in hover:bg-red-500"
                onClick={() => setIsActiveComment(false)}
            >
                <IoClose className="text-3xl max-sm:text-xl" />
            </div>
        </div>
        <div className='w-full max-h-[300px] max-sm:max-h-[250px] overflow-y-scroll custom-scrollbar flex items-start justify-start'>
            {
                loading ?
                (
                    <div className='w-full h-[100px] flex items-center justify-center'><Spinner/></div>
                ) : 
                (
                    commentsData ? 
                    (
                        <div className='w-full flex flex-col items-start gap-2'>
                            {commentsData.map((comment) => (
                                <div className='w-full flex items-start justify-start gap-2'>
                                    <div className='w-[40px] h-[40px] max-sm:w-[30px] max-sm:h-[30px] rounded-full border-2 border-gray-500 flex items-center justify-center'>
                                        <img src={getUserImg(comment.username)} alt='user-ing' className=' object-cover'/>
                                    </div>
                                    <div className='max-w-[85%] flex flex-col items-start justify-start gap-1 bg-[#333] p-2 rounded-lg max-sm:p-1 max-sm:gap-0'>
                                        <h2 className='text-base font-bold text-gray-100 max-sm:text-sm'>{comment.username}</h2>
                                        <p className='text-sm font-medium text-gray-400 max-sm:text-xs'>{comment.message}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) 
                    :
                    (
                        <div className='w-full flex flex-col items-center gap-2'>
                            <GoCommentDiscussion className='text-6xl text-gray-400'/>
                            <div className='w-full flex flex-col items-center'>
                                <h3 className='text-xl font-bold text-gray-100'>No comments yet</h3>
                                <p className='text-base font-medium text-gray-400'>Be the first to comment.</p>
                            </div>
                        </div>
                    )
                )
            }
        </div>
        <div className='w-full flex flex-col items-center gap-2'>
            <input
                type='text'
                name='message'
                value={inputData.message}
                onChange={inputHandler}
                placeholder={`Comment as ${formData.username}`}
                className='w-full text-lg max-sm:text-sm font-medium bg-black placeholder:text-gray-500 py-2 px-4 rounded-full outline-none border-2 text-white border-black transition duration-300 ease-in focus:border-blue-500'
            />
            <div className='w-full flex items-center justify-between'>
                <span className='text-yellow-500 text-base font-semibold max-sm:text-xs'>{commentsData?.length} comments</span>
                <button onClick={commentHandler} className='flex items-center text-lg max-sm:text-sm font-bold text-white border-none bg-rose-500 py-1 px-4 rounded-md transition duration-300 ease-in hover:bg-rose-600 gap-2'>Post {isLoading && (<SmSpinner/>)}</button>
            </div>
        </div>
    </div>
  )
}

export default Comment
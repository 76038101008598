import React, { useContext, useEffect } from 'react'
import { AppContext } from '../../context/AppContext'
// skills logo
import JsLogo from "../../skills-logo/Javascript.svg";
import ReactLogo from "../../skills-logo/React.png";
import PythonLogo from "../../skills-logo/Python.webp";
import CppLogo from "../../skills-logo/c++_logo.svg";
import NodeLogo from "../../skills-logo/NodeJs.svg";
import HtmlLogo from "../../skills-logo/HTML.png";
import CssLogo from "../../skills-logo/CSS.png";
import Tailwind from "../../skills-logo/Tailwind.png";
import MongoDBLogo from "../../skills-logo/MongoDB.svg";
import ExpressLogo from "../../skills-logo/Express.png";
import JavaLogo from "../../skills-logo/java.webp";
import VercelLogo from "../../skills-logo/Vercel.svg";
import GitLogo from "../../skills-logo/Git.svg";
import GithubLogo from "../../skills-logo/Github.svg";
import bashLogo from "../../skills-logo/Bash.svg";
import BootStrapLogo from "../../skills-logo/Bootstrap.svg";
import ChatJsLogo from "../../skills-logo/ChartJs.svg";
import NextJsLogo from "../../skills-logo/Next.svg";
import SqlLogo from "../../skills-logo/MySQL.svg";
import ReduxLogo from "../../skills-logo/Redux.svg";
import DockerLogo from "../../skills-logo/Docker.svg";
import GraphQlLogo from "../../skills-logo/Graphql.svg";
import TypescriptLogo from "../../skills-logo/Typescript.svg";

const skills = [
  JsLogo, ReactLogo, ExpressLogo, NodeLogo, ReduxLogo, Tailwind,
  HtmlLogo, CssLogo,  MongoDBLogo,  SqlLogo, NextJsLogo, TypescriptLogo,
  CppLogo, PythonLogo, JavaLogo, VercelLogo, 
  GitLogo, GithubLogo, bashLogo, BootStrapLogo, ChatJsLogo,
  DockerLogo, GraphQlLogo
];

const MySkills = () => {
  const {webData, getWebDetails} = useContext(AppContext);

  useEffect(() => {
    if(!webData){
        getWebDetails();
    }
  },[]);

  return (
    <div className='w-full flex justify-bwtween items-start text-white gap-8 px-8 max-md:flex-col max-sm:px-4 max-sm:gap-4'>
      <div className='w-full flex flex-col items-start gap-4'>
        <div className='w-full flex flex-col gap-2 max-md:gap-0'>
          <h1 className='text-3xl font-bold text-blue-600 overflow-hidden max-md:text-xl'><span className='text-5xl max-md:text-3xl'>M</span>e and</h1>
          <h2 className='text-3xl font-bold text-blue-600 overflow-hidden max-md:text-xl'>My Tech Stack</h2>
        </div>

        <p className='text-base font-normal text-gray-200 text-justify max-sm:text-sm'>{webData?.skillDesc}</p>
      </div>

      <div className="w-full flex flex-wrap justify-center items-start gap-8 bg-[#333] p-4 rounded-xl max-sm:gap-4">
        {skills.map((logo, index) => (
            <img key={index} src={logo} alt="Skill logo" className='w-[60px] object-cover transition duration-300 ease-in hover:transform hover:scale-110 max-lg:w-[50px] max-sm:w-[40px]' />
        ))}
      </div>
    </div>
  )
}

export default MySkills
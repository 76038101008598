import React from 'react'
import logo from '../../logo.png'
import { useNavigate } from 'react-router-dom'

const Logo = () => {
  const navigate = useNavigate();
  return (
    <div className='w-[120px] cursor-pointer max-sm:w-[80px]' onClick={() => navigate('/')}>
      <img src={logo} alt='logo' className='w-full'/>
    </div>
  )
}

export default Logo
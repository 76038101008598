import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

const NavItems = () => {
  const {isLoggedIn} = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [pathName, setPathName] = useState('');

  useEffect(() => {
    const currentPath = location.pathname === '/' ? 'home' : location.pathname.split('/').at(-1);
    setPathName(currentPath);
  }, [location.pathname]);

  return (
    <ul className={`h-full px-4 flex items-center gap-3 max-lg:gap-1 max-md:flex-col max-md:justify-center`}>
      <li
        onClick={() => {
          navigate('/');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
        className={`text-lg font-semibold cursor-pointer text-gray-200 border-none px-3 py-1 rounded transition duration-200 ease-in ${pathName === 'home' ? 'bg-[#333]' : 'bg-transparent hover:bg-[#333]'}`}
      >
        Home
      </li>
      <li
        onClick={() => {
          navigate('/about');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
        className={`text-lg font-semibold cursor-pointer text-gray-200 border-none px-3 py-1 rounded transition duration-200 ease-in ${pathName === 'about' ? 'bg-[#333]' : 'bg-transparent hover:bg-[#333]'}`}
      >
        About
      </li>
      <li
        onClick={() => {
          navigate('/skills');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
        className={`text-lg font-semibold cursor-pointer text-gray-200 border-none px-3 py-1 rounded transition duration-200 ease-in ${pathName === 'skills' ? 'bg-[#333]' : 'bg-transparent hover:bg-[#333]'}`}
      >
        Skills
      </li>
      <li
        onClick={() => {
          navigate('/services');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
        className={`text-lg font-semibold cursor-pointer text-gray-200 border-none px-3 py-1 rounded transition duration-200 ease-in ${pathName === 'services' ? 'bg-[#333]' : 'bg-transparent hover:bg-[#333]'}`}
      >
        Services
      </li>
      <li
        onClick={() => {
          navigate('/blog');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
        className={`text-lg font-semibold cursor-pointer text-gray-200 border-none px-3 py-1 rounded transition duration-200 ease-in ${pathName === 'blog' ? 'bg-[#333]' : 'bg-transparent hover:bg-[#333]'}`}
      >
        Blog
      </li>

      {!isLoggedIn && (
        <li
          onClick={() => {
          navigate('/signup');
          window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          className={`text-lg font-semibold cursor-pointer text-blue-600 border-none px-3 py-1 rounded transition duration-200 ease-in ${pathName === 'signup' ? 'bg-[#333]' : 'bg-transparent hover:bg-[#333]'}`}
        >
          Join
        </li>
      )}
      
      <li
        onClick={() => {
          navigate('/contact');
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
        className={`text-lg font-semibold cursor-pointer px-3 py-1 border border-blue-500 rounded transition duration-200 ease-in ${pathName === 'contact' ? 'bg-blue-500 text-white' : 'text-blue-500 bg-transparent hover:bg-[#222]'}`}
      >
        Contact
      </li>
    </ul>
  );
};

export default NavItems;

import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { IoClose } from "react-icons/io5";
import { IoIosSave } from "react-icons/io";
import { AiFillLike } from "react-icons/ai";
import SmSpinner from "../loader/SmSpinner";
import { toast } from "react-toastify";

const AskEmail = () => {
  const {
    setIsActiveAskEmail,
    isLoading,
    formData,
    setFormData,
    validateEmail,
    profileLike,
    portfolioLike,
    likeTag,
    blogLike
  } = useContext(AppContext);
  const [error, setError] = useState("");

  function chnageHandler(event) {
    setFormData((prevData) => ({
      ...prevData,
      [event.target.name]: event.target.value,
    }));
  }

  const saveHandler = () => {
    if (formData.username !== "" && formData.email !== "") {
      if (validateEmail(formData.email)) {
        toast.success("Saved Successfully");
        setError("");
        return;
      }
      setFormData((prevData) => ({
        ...prevData,
        email: "",
      }));
      toast.error("Invaild Email");
      setError("invaild email");
    } else {
      if (formData.username === "") {
        setError("Please enter your name");
        toast.warning("Please enter your name");
        return;
      }
      if (formData.email === "") {
        setError("Please enter your email");
        toast.warning("Please enter your email");
      }
    }
  };

  const likeHandler = () => {
    if(formData.email === ''){
      setError('Please enter your email');
      setIsActiveAskEmail(true);
      return;
    }
    if(formData.username === ''){
      setError('Please enter your name');
      setIsActiveAskEmail(true);
      return;
    }
    if (likeTag.tag === "like profile") {
      profileLike();
    }
    if(likeTag.tag === 'like project'){
      portfolioLike(likeTag.project_id, likeTag.index);
    }
    if(likeTag.tag === 'like blog'){
      blogLike(likeTag.blog_id, likeTag.index);
    }
    setIsActiveAskEmail(false);
  };

  return (
    <div className="w-[500px] bg-[#222] rounded-lg p-4 flex flex-col max-sm:w-[95vw]">
      <div className="w-full flex justify-between items-center">
        <div className="text-2xl font-bold text-blue-500 text-style capitalize max-sm:text-lg">
          {likeTag.tag}
        </div>
        <div
          className="w-[40px] h-[40px] max-sm:w-[30px] max-sm:h-[30px] flex justify-center items-center rounded-full bg-blue-200 cursor-pointer transition duration-300 ease-in hover:bg-red-500"
          onClick={() => setIsActiveAskEmail(false)}
        >
          <IoClose className="text-3xl max-sm:text-xl" />
        </div>
      </div>
      <form
        className="w-full flex flex-col gap-4"
        onSubmit={(e) => e.preventDefault()}
      >
        <label className="w-full flex flex-col">
          <p className="text-md font-semibold text-gray-200 max-sm:text-sm">
            Name<span className="text-red-500">*</span>
          </p>
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={chnageHandler}
            placeholder="Enter Your Full Name"
            className="text-lg max-sm:text-base font-semibold py-2 px-4 text-black bg-blue-200 rounded border-2 border-blue-500 outline-none focus:border-red-600 placeholder:text-gray-500"
          />
        </label>

        <label className="w-full flex flex-col">
          <p className="text-md font-semibold text-gray-200 max-sm:text-sm">
            Email<span className="text-red-500">*</span>
          </p>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={chnageHandler}
            placeholder="Enter Email"
            className="text-lg max-sm:text-base font-semibold py-2 px-4 text-black bg-blue-200 rounded border-2 border-blue-500 outline-none focus:border-red-600 placeholder:text-gray-500"
          />
        </label>

        {error && (
          <div className="w-full flex items-center justify-between py-1 px-3 bg-transparent border border-red-500 text-red-500 rounded-sm">
            <p className="text-md font-semibold max-sm:text-xs">{error}</p>
            <IoClose
              className="text-lg cursor-pointer text-white max-sm:text-base"
              onClick={() => setError("")}
            />
          </div>
        )}

        <div className="w-full flex items-center justify-center gap-4">
          <button
            onClick={saveHandler}
            className="w-full text-lg max-sm:text-sm font-bold flex items-center justify-center gap-4 text-white border-2 border-blue-500 rounded-md py-2 transition duration-200 ease-in hover:bg-blue-500"
          >
            <IoIosSave className="text-xl max-sm:text-base" /> Save
          </button>
          {likeTag.tag !== 'comment' &&
          (<button
            onClick={likeHandler}
            className="w-full text-lg  max-sm:text-sm font-bold flex items-center justify-center gap-4 text-white border-2 border-blue-500 rounded-md py-2 transition duration-200 ease-in hover:bg-blue-500"
          >
            {isLoading ? <SmSpinner /> : <AiFillLike className="text-xl max-sm:text-base" />}{" "}
            Like
          </button>)}
        </div>
      </form>
    </div>
  );
};

export default AskEmail;

import { createContext, useEffect, useState } from "react";
import axios from "axios"
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const AppContext = createContext();

function AppContextProvider({ children }) {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [webData, setWebData] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [likeTag, setLikeTag] = useState({tag:'', project_id:'', index:''});
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [likeCounts, setLikeCounts] = useState(0);
  const [isLikedProfile, setIsLikedProfile] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
  });
  const [isActiveAskEmail, setIsActiveAskEmail] = useState(false);
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [projectData, setProjectData] = useState([]);
  const [likeProjects, setLikeProjects] = useState([]);
  const [blogData, setBlogData] = useState(null);
  const [currBlogPage, setCurrBlogPage] = useState(1);
  const [testimoialData, setTestimonalData] = useState(null);
  const [currTestimonialPage, setCurrTestmonialPage] = useState(0);
  const [isActiveMenuBtn, setIsActiveMenuBtn] = useState(false);
  const [blogPageData, setBlogPageData] = useState(null);
  const [descInfo, setDescInfo] = useState([]);
  const [isActiveComment, setIsActiveComment] = useState(false);
  const [projectCurrPage, setProjectCurrPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [projectLoading, setProjectLoading] = useState(false);

  function validateEmail(email) {
    // Regular expression to validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the regular expression
    return emailRegex.test(email);
  }

  const scrollToDiv = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const getWebDetails = async () => {
    try {
      const response = await fetch(`${baseUrl}/portfolio-details`);
      
      // Check if the response is okay
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      // Try parsing the response as JSON
      const data = await response.json();
      
      if (data.success) {
        setWebData(data.data);
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      // If JSON parsing fails, catch the error and display a message
      if (err.name === 'SyntaxError') {
        console.error('Response was not JSON:', err);
        toast.error('Received an invalid response from the server.');
      } else {
        console.error(err.message);
        toast.error('Something went wrong.');
      }
    }
  };  

  const AuthUser = async () => {
    try {
      const token = localStorage.getItem("NPToken");
      if (!token) {
        throw new Error("Token not found. Please log in again.");
      }
      const url = `${baseUrl}/dashboard`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ token }),
      });
      const data = await response.json();
      if (response.ok && data.success) {
        setIsLoggedIn(true);
        navigate('/');
      } else {
        toast.warning(
          data.message || "Failed to authenticate. Please try again."
        );
      }
    } catch (err) {
      console.log(err.message);
      console.log(err.message)
    }
  };

  useEffect(() => {
    handleOperations();
  }, []);

  useEffect(() => {
    if (projectData) {
      const newArr = [];
      projectData.map(() => {
        newArr.push(false);
      });
      setLikeProjects(newArr);
    }
  }, [projectData]);

  const handleOperations = async () => {
    try {
      setIsLoading(true);
      await getWebDetails();
      await getLikeCounts();
      await getProjectsDetails();
      await getTestimonalsDetails();
      await AuthUser();
      await getLocation();
    } catch (err) {
      toast.error(err.message);
    } finally{
      setIsLoading(false);
    }
  };

  const getLikeCounts = async () => {
    try {
      const url = `${baseUrl}/like-counts`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data.success) {
        setLikeCounts(data.count);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const profileLike = async () => {
    setIsLikedProfile(true);
    try {
      const url = `${baseUrl}/like-profile`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (data.success) {
        setLikeCounts(data.count);
        setIsLikedProfile(true);
        toast.success(data.message);
      } else {
        toast.warning(data.message);
        setIsLikedProfile(false);
      }
    } catch (err) {
      setIsLikedProfile(false);
      toast.error(err.message);
    }
  };

  const portfolioLike = async (projectId, index) => {
    setLikeProjects((prevState) => {
      const currState = [...prevState];
      currState[index] = true;
      return currState;
    });

    try {
      const url = `${baseUrl}/project/like`;
      const bodyData = {
        email: formData.email,
        username: formData.username,
        project_id: projectId,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
      });

      const data = await response.json();

      if (data.success) {
        toast.success(data.message);
      } else {
        toast.warning(data.message);
        setLikeProjects((prevState) => {
          const currState = [...prevState];
          currState[index] = false;
          return currState;
        });
      }
    } catch (err) {
      setLikeProjects((prevState) => {
        const currState = [...prevState];
        currState[index] = false;
        return currState;
      });
      toast.error(err.message);
    }
  };

  const getProjectsDetails = async (page = 1) => {
    try {
      setProjectLoading(true);
  
      // Fetch projects from the backend, passing the current page as a query parameter
      const res = await axios.get(`${baseUrl}/projects?page=${page}`);
      
      // Append new projects to the existing list, filtering out duplicates using unique _id
      setProjectData(prevProjects => {
        const newProjects = res.data.projects.filter(newProject =>
          !prevProjects.some(project => project._id === newProject._id)
        );
        return [...prevProjects, ...newProjects];
      });
  
      // Update hasMore state to indicate whether more projects are available to load
      setHasMore(res.data.hasMore);
    } catch (err) {
      console.error('Error fetching projects:', err);
      toast.error('Failed to fetch projects. Please try again later.'); // Show user-friendly error message
    } finally {
      setProjectLoading(false); // Ensure loading state is set to false after the request
    }
  };  

  const getBlogsDetails = async () => {
    try{
      const url = `${baseUrl}/blog`;
      const response = await fetch(url, {
        method:'POST',
        headers:{
          "Content-Type": "application/json",
        },
        body: JSON.stringify({window_size: screenWidth})
      });
      const data = await response.json();
      if (data.success) {
        setBlogData(data.blogs);
        setCurrBlogPage(1);
      }
      else{
        toast.error(data.message)
      }
    } catch (err) {
      toast.error(err.message);
    }
  }


  const getBlogsPage = async () => {
    try{
      setIsLoading(true);
      const url = `${baseUrl}/blog-page`;
      const response = await fetch(url);
      const data = await response.json();
      if (data.success) {
        setBlogPageData(data);
      }
      else{
        toast.error(data.message)
      }
    } catch (err) {
      toast.error(err.message);
    } finally{
      setIsLoading(false);
    }
  }

  const getTestimonalsDetails = async () => {
    try{
      const url = `${baseUrl}/testimoial`;
      const response = await fetch(url);
      const data = await response.json();
      if (data.success) {
        setTestimonalData(data.testimonial);
        setCurrTestmonialPage(0);
      }
      else{
        toast.error(data.message)
      }
    } catch (err) {
      toast.error(err.message);
    }
  }

  const blogLike = async(blogId, index) => {
    setDescInfo((prevDesc) => {
      return prevDesc.map((item, i) => {
        if (i === index) {
          const isLike = true;
          return { ...item, isLike };
        }
        return item;
      });
    });

    try {
      const url = `${baseUrl}/blog/like`;
      const bodyData = {
        email: formData.email,
        username: formData.username,
        blog_id: blogId,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
      });

      const data = await response.json();

      if (data.success) {
        setDescInfo((prevDesc) => {
          return prevDesc.map((item, i) => {
            if (i === index) {
              const isLike = true;
              return { ...item, isLike };
            }
            return item;
          });
        });
        toast.success(data.message);
      } else {
        setDescInfo((prevDesc) => {
          return prevDesc.map((item, i) => {
            if (i === index) {
              const isLike = false;
              return { ...item, isLike };
            }
            return item;
          });
        });
        toast.warning(data.message);
      }
    } catch (err) {
      setDescInfo((prevDesc) => {
        return prevDesc.map((item, i) => {
          if (i === index) {
            const isLike = false;
            return { ...item, isLike };
          }
          return item;
        });
      });
      toast.error(err.message);
    }
  }

  const getLocation = async () => {
    const API_KEY = process.env.REACT_APP_API_KEY;
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          axios
            .get(
              `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${API_KEY}`
            )
            .then((response) => {
              if (response.data.results.length > 0) {
                const result = response.data.results[0];
                const city =
                  result.components.city ||
                  result.components.town ||
                  result.components.village;
                const area =
                  result.components.suburb ||
                  result.components.neighbourhood ||
                  result.components.locality;
                setCity(city);
                setArea(area);
              }
            })
            .catch((error) => {});
        },
        (error) => {}
      );
    }
  };

  const AddViewPortfolio = async () => {
    try {
      const isViewed = sessionStorage.getItem("isViewedPortfolioNP");
      if (!isViewed && city && area) {
        sessionStorage.setItem("isViewedPortfolioNP", true);
        const url = `${baseUrl}/view-from`;
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ location: `${city}, ${area}` }),
        });
        const data = await response.json();
        if (data.success) {
          console.log(data.message);
        } else {
          console.error("Failed to log view:", data.message);
        }
      }
    } catch (err) {
      console.error("Error occurred in AddViewPortfolio:", err);
    }
  };
  useEffect(() => {
    AddViewPortfolio();
  }, [city]);


  useEffect(() => {
    // Function to handle screen resizing
    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    // Add event listener to handle window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
        window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    getBlogsDetails();
  },[screenWidth]);

  const value = {
    scrollToDiv,
    webData,
    setWebData,
    likeTag,
    setLikeTag,
    isLoading,
    setIsLoading,
    isLoggedIn,
    setIsLoggedIn,
    likeCounts,
    setLikeCounts,
    isLikedProfile,
    setIsLikedProfile,
    formData,getLocation,
    setFormData,
    isActiveAskEmail,
    setIsActiveAskEmail,
    validateEmail,
    profileLike, 
    portfolioLike,
    AuthUser,
    city,
    setCity,
    area,
    setArea,
    projectData,
    setProjectData,
    likeProjects,
    setLikeProjects,
    blogData, 
    setBlogData,
    getBlogsDetails,
    currBlogPage, setCurrBlogPage,
    testimoialData, setTestimonalData, getTestimonalsDetails, currTestimonialPage, setCurrTestmonialPage,
    isActiveMenuBtn, setIsActiveMenuBtn,
    getWebDetails,
    getBlogsPage, blogPageData, setBlogPageData, blogLike, descInfo, setDescInfo,
    isActiveComment, setIsActiveComment,
    projectCurrPage, setProjectCurrPage,
    projectLoading, setProjectLoading, hasMore, setHasMore, getProjectsDetails
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
export default AppContextProvider;

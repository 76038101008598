import React, { useEffect, useState } from 'react';
import Navbar from '../components/sections/Navbar';
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import SmSpinner from '../components/loader/SmSpinner';
import { toast } from 'react-toastify';
import { TiWarning } from "react-icons/ti";
import { MdReportGmailerrorred } from "react-icons/md";
import FooterSM from '../components/sections/FooterSM';

const SignUp = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const navigate = useNavigate();
    const [isVisiablePass, setIsVisiablePass] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [emailError, setEmailError] = useState({ error: false, message: '' });
    const [passwordError, setPasswordError] = useState({ error: false, message: '' });
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        password: ''
    });

    useEffect(() => {
        window.document.title = 'Sign up'
    },[]);

    function inputHandler(event) {
        setError('');
        if (event.target.name === 'email') {
            setEmailError({ error: false, message: '' });
        }
        if (event.target.name === 'password') {
            setPasswordError({ error: false, message: '' });
        }
        setFormData((prevData) => ({
            ...prevData,
            [event.target.name]: event.target.value
        }));
    }

    const isFormFilled = () => {
        return Object.values(formData).every(value => value.trim() !== '');
    };

    const signupHandler = async () => {
        if (!isFormFilled()) {
            setError('Please fill out all fields.');
            return;
        }

        try {
            setIsLoading(true);
            const url = `${baseUrl}/user/create`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();

            if (response.ok && data.success === true) {
                toast.success(data.message);
                navigate('/login');
            } else {
                if (data.tag === 'email') {
                    setEmailError({ error: true, message: data.message });
                } else if (data.tag === 'password') {
                    setPasswordError({ error: true, message: data.message });
                } else {
                    setError(data.message || 'An unexpected error occurred. Please try again.');
                }
            }
        } catch (err) {
            console.error('Error:', err);
            setError('Failed to sign up. Please check your network connection or try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='w-full bg-black flex flex-col items-center gap-6 max-sm:gap-4'>
            <Navbar />
            <div className='w-full h-full flex justify-around items-center box-content pt-[80px] max-sm:flex-col'>
                <div className='w-[400px] max-md:w-[300px] max-sm:w-[80%]'>
                    <img src='https://res.cloudinary.com/do1xweis7/image/upload/v1723816607/loginPage_na8syd.png' alt='3D-img' className='w-full' />
                </div>
                <div className='w-[550px] flex flex-col text-white bg-[#111] py-6 px-8 max-sm:p-4 rounded-xl gap-6 max-sm:gap-4 max-md:w-[400px] max-sm:w-full'>
                    <div className='w-full flex flex-col gap-1'>
                        <h1 className='text-xl max-md:text-lg font-bold text-white'>Create new account<span className="text-xl text-blue-500">.</span></h1>
                        <div className='w-full flex items-center justify-start gap-2'>
                            <p className='text-md max-md:text-sm text-[#444] font-medium'>Already A Member?</p>
                            <p className='text-md max-md:text-sm font-semibold text-blue-500 cursor-pointer transition duration-200 ease-in hover:underline' onClick={() => navigate('/login')}>Log In</p>
                        </div>
                    </div>

                    <div className='w-full flex flex-col gap-4 max-sm:gap-3'>
                        <div className="w-full flex justify-center items-center gap-2 max-sm:flex-col max-sm:gap-3">
                            <label className='w-full flex flex-col gap-1'>
                                <p className='text-md max-md:text-sm font-semibold text-gray-500'>First Name</p>
                                <input
                                    type='text'
                                    name='firstname'
                                    value={formData.firstname}
                                    onChange={inputHandler}
                                    placeholder='John'
                                    className='text-lg max-md:text-base font-semibold py-2 px-4 max-sm:px-2 bg-[#333] text-white placeholder:text-gray-500 rounded outline-none border-2 border-transparent transition duration-300 ease-in hover:border-blue-300 focus:border-blue-500'
                                />
                            </label>

                            <label className='w-full flex flex-col gap-1'>
                                <p className='text-md max-md:text-sm font-semibold text-gray-500'>Last Name</p>
                                <input
                                    type='text'
                                    name='lastname'
                                    value={formData.lastname}
                                    onChange={inputHandler}
                                    placeholder='Doe'
                                    className='text-lg max-sm:px-2 max-md:text-base font-semibold py-2 px-4 bg-[#333] text-white placeholder:text-gray-500 rounded outline-none border-2 border-transparent transition duration-300 ease-in hover:border-blue-300 focus:border-blue-500'
                                />
                            </label>
                        </div>

                        <label className='w-full flex flex-col gap-1'>
                            <p className='text-md max-md:text-sm font-semibold text-gray-500'>Email address</p>
                            <input
                                type='email'
                                name='email'
                                value={formData.email}
                                onChange={inputHandler}
                                placeholder='example@gmail.com'
                                className={`text-lg max-sm:px-2 max-md:text-base font-semibold py-2 px-4 bg-[#333] text-white placeholder:text-gray-500 rounded outline-none border-2 ${emailError.error ? 'border-red-500' : 'border-transparent transition duration-300 ease-in hover:border-blue-300 focus:border-blue-500'}`}
                            />
                            {
                                emailError.error && (
                                    <div className='w-full flex items-center justify-start gap-2 text-red-500 text-sm max-sm:text-xs font-medium'>
                                        <MdReportGmailerrorred className='text-lg max-sm:text-base text-orange-500' />
                                        {emailError.message}
                                    </div>
                                )
                            }
                        </label>

                        <label className='w-full flex flex-col gap-1 relative'>
                            <p className='text-md max-md:text-sm font-semibold text-gray-500'>Password</p>
                            <input
                                type={isVisiablePass ? 'text' : 'password'}
                                name='password'
                                value={formData.password}
                                onChange={inputHandler}
                                placeholder='password'
                                className={`text-lg max-sm:px-2 max-md:text-base font-semibold py-2 pl-4 pr-12 bg-[#333] text-white placeholder:text-gray-500 rounded outline-none border-2 ${passwordError.error ? 'border-red-500' : 'border-transparent transition duration-300 ease-in hover:border-blue-300 focus:border-blue-500'}`}
                            />
                            <div className='absolute top-9 right-2 text-3xl max-md:text-xl cursor-pointer text-gray-500' onClick={() => setIsVisiablePass(!isVisiablePass)}>
                                {isVisiablePass ? (<IoMdEyeOff />) : (<IoMdEye />)}
                            </div>
                            {
                                passwordError.error && (
                                    <div className='w-full flex items-center justify-start gap-2 text-red-500 text-sm max-sm:text-xs font-medium'>
                                        <MdReportGmailerrorred className='text-lg max-sm:text-base text-orange-500' />
                                        {passwordError.message}
                                    </div>
                                )
                            }
                        </label>

                        {error !== '' && (
                            <div className='w-full flex items-center justify-start gap-2 py-1 px-3 text-md max-sm:text-xs text-red-500 border border-red-500 font-semibold'>
                                <TiWarning className='text-xl max-sm:text-base text-yellow-500' />
                                <p>{error}</p>
                            </div>
                        )}

                        <div className='w-full flex justify-start'>
                            <button className='text-lg max-md:text-base flex items-center gap-4 font-extrabold text-white border-2 border-blue-500 bg-blue-500 py-1 px-4 rounded-3xl transition duration-300 ease-in hover:bg-transparent' onClick={signupHandler}>
                                {isLoading && (<SmSpinner />)}
                                Sign up
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <FooterSM/>
        </div>
    );
};

export default SignUp;

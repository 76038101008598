import React, { useContext } from 'react';
import './App.css'
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import { AppContext } from './context/AppContext';
import AskEmail from './components/pop-up/AskEmail';
import About from './pages/About';
import Skills from './pages/Skills';
import Services from './pages/Services';
import Contact from './pages/Conatct';
import Blog from './pages/Blog'
import SignUp from './pages/SignUp';
import UserContextProvider from './context/UserContext';
import NavItems from './components/navbar/NavItems';
import Comment from './components/pop-up/Comment';
import ForrgetPass from './pages/ForrgetPass';
import PdfViewer from './components/sections/PdfViwer';

function App() {
  const {isActiveAskEmail, isActiveMenuBtn, isActiveComment} = useContext(AppContext);
  return (
    <div className='w-screen min-h-screen'>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/skills' element={<Skills/>}/>
        <Route path='/services' element={<Services/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/blog' element={<Blog/>}/>
        <Route path='/login' element={<Login/>}/>
        <Route path='/signup' element={<SignUp/>}/>
        <Route path='/forget-password' element={<ForrgetPass/>}/>
        <Route path='/resume-viewer' element={<PdfViewer/>}/>
        <Route path='/about/resume-viewer' element={<PdfViewer/>}/>
        <Route path='/dashboard' element={
          <UserContextProvider>
            <PrivateRoute>
              <Dashboard/>
            </PrivateRoute>
          </UserContextProvider>
        }/>

        <Route path='*' element={<Home/>}/>
      </Routes>

      {
        isActiveAskEmail && (<div className='w-full h-screen flex justify-center items-center backdrop-blur fixed top-0 left-0 z-50'><AskEmail/></div>)
      }

      {
        isActiveComment && (<div className='w-full h-screen flex justify-center items-center backdrop-blur fixed top-0 left-0 z-50 max-sm:items-end'><Comment/></div>)
      }

      {
        isActiveMenuBtn && (<div className='w-full h-screen flex justify-center items-center backdrop-blur fixed top-0 left-0 z-40'><NavItems/></div>)
      }
    </div>
  );
}

export default App;

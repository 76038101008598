import React from 'react';
import Logo from '../navbar/Logo';
import NavItems from '../navbar/NavItems';
import Buttons from '../navbar/Buttons';

const Navbar = () => {
  return (
    <div className='w-full h-[75px] flex items-center justify-between px-4 fixed top-0 bg-[#1111] backdrop-blur-md z-50 max-sm:h-[65px] max-sm:px-2'>
      <Logo/>
      <div className='max-md:hidden'><NavItems/></div>
      <Buttons/>
    </div>
  )
}

export default Navbar
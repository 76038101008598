import React, { useRef, useEffect, useContext } from 'react'
import Typed from "typed.js";
import { HiOutlineDownload } from "react-icons/hi";
import { AppContext } from '../../context/AppContext';
import { useNavigate } from 'react-router-dom';

const AboutMe = () => {
    const typedElement = useRef(null);
    const { webData, scrollToDiv } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        // Options for the Typed.js
        const options = {
            strings: [
                "Full Stack Developer",
                "Web Developer",
                "Frontend Developer",
                "Backend Developer",
                "Software Developer",
                "Coder",
            ],
            loop: true,
            typeSpeed: 100,
            backSpeed: 100,
            backDelay: 1000,
        };

        // Initialize Typed.js on the referenced element
        const typed = new Typed(typedElement.current, options);

        // Cleanup effect (destroys the instance of Typed.js)
        return () => {
            typed.destroy();
        };
    }, [webData]);

    return (
        <div className='w-[90%] h-[400px] flex bg-[#111] rounded-lg about-bg max-md:flex-col max-md:h-auto max-md:items-center overflow-hidden max-md:w-full'>
            <div className='w-[1220px] h-[600px] relative overflow-y-hidden flex justify-center items-center max-lg:w-[800px] max-md:w-full max-md:h-[300px] max-sm:h-[250px]'>
                <div className='relative flex justify-center items-center pt-28 max-sm:pt-20'>
                    <div className='w-[580px] h-[600px] border border-blue-800 rounded-full max-lg:w-[400px] max-lg:h-[400px] max-md:w-[350px] max-md:h-[350px] max-sm:w-[300px] max-sm:h-[300px] flex justify-center items-center'>
                        <div className='w-[500px] h-[500px] bg-blue-900 rounded-full max-lg:w-[320px] max-lg:h-[320px] max-md:w-[300px] max-md:h-[300px] max-sm:w-[250px] max-sm:h-[250px] flex justify-center items-center'>
                            <div className='w-[400px] h-[400px] border border-blue-300 rounded-full max-lg:w-[280px] max-lg:h-[280px] max-md:w-[250px] max-md:h-[250px] max-sm:w-[200px] max-sm:h-[200px]'></div>
                        </div>
                    </div>
                </div>
                <img src='https://res.cloudinary.com/do1xweis7/image/upload/v1725107118/about_img_2_teymkk.png' alt='user-img' className='z-20 absolute bottom-28 w-[400px] max-lg:w-[250px] max-lg:bottom-36 max-md:w-[180px] max-md:left-[50%] max-md:translate-x-[-50%] max-md:bottom-0' />
            </div>

            <div className='w-full h-full flex flex-col justify-center items-center gap-6 px-4 max-md:p-4 max-md:gap-4 max-lg:gap-1'>
                <div className='w-full flex flex-col items-start max-md:items-center'>
                    <h2 className='text-2xl font-bold uppercase text-white max-md:text-lg max-md:text-center'>{webData?.name}</h2>
                    <p className='text-lg font-medium text-gray-400 max-md:text-center'>I'm a <span ref={typedElement} className="role text-xl font-bold text-orange-500" /></p>
                </div>

                <div className='w-full text-md font-normal text-gray-300 text-justify max-md:text-justify max-sm:text-sm'>{`${webData?.aboutMe}`}</div>

                <div className='w-full flex items-center justify-center gap-4 p-1 text-white max-sm:flex-col'>
                    <button onClick={() => scrollToDiv('projects')} className='text-xl font-bold btn-bg py-2 px-4 rounded-md max-sm:text-base max-lg:text-base'>My Project</button>
                    <button onClick={() => navigate('resume-viewer')} className='text-xl font-bold py-2 px-4 rounded-md border border-blue-500 flex items-center gap-2 max-sm:text-base max-lg:text-base max-lg:gap-1'><HiOutlineDownload /> Download CV</button>
                </div>
            </div>
        </div>
    )
}

export default AboutMe;

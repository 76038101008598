import { createContext, useState } from "react";
import { toast } from "react-toastify";

export const UserContext = createContext();

function UserContextProvider({children}){
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isActiveAddProjectForm, setIsActiveAddProjectForm] = useState(false);
    const [isActiveAddBlogForm, setIsActiveBlogForm] = useState(false);

    const getAdminDetails = async () => {
        try{
            setIsLoading(true);
            const token = localStorage.getItem('NPToken');
            if (!token) {
                throw new Error('Token not found. Please log in again.');
            }
            const url = `${baseUrl}/admin`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    token
                })
            });
            const data = await response.json();
            if(data.success){
                setData(data);
            }
        } catch(err){
            toast.error(err.message);
        } finally{
            setIsLoading(false);
        }
    }

    const value = {
        data, setData,
        isLoading, setIsLoading,
        getAdminDetails,
        isActiveAddProjectForm, setIsActiveAddProjectForm,
        isActiveAddBlogForm, setIsActiveBlogForm
    };

    return  (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
}
export default UserContextProvider;
import React, { useContext } from 'react'
import { UserContext } from '../../context/UserContext';
import { IoMdAddCircle } from "react-icons/io";

const AddBlog = () => {
  const {setIsActiveBlogForm} = useContext(UserContext);
  return (
    <div className='w-full flex flex-col items-start gap-6'>
      <div className='w-full flex items-center justify-between'>
        <button onClick={() => setIsActiveBlogForm(true)} className='flex items-center gap-1 text-lg font-semibold text-gray-100 border py-1 px-2 rounded bg-blue-500 border-blue-500 transition duration-300 ease-in hover:bg-blue-600'>
          <IoMdAddCircle className='text-xl text-blue-200'/>create blog post
        </button>
      </div>
    </div>
  )
}

export default AddBlog
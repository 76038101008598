import React, { useContext, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../components/sections/Navbar";
import Info from "../components/home-com/Info";
import ThreeDScene from "../components/home-com/ThreeDScene";
import AboutMe from "../components/home-com/AboutMe";
import { PiNotepad } from "react-icons/pi";
import { BsGraphUpArrow } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import { CiLaptop } from "react-icons/ci";
import Portfolios from "../components/home-com/Portfolios";
import { IoMdArrowRoundForward } from "react-icons/io";
import Blogs from "../components/home-com/Blogs";
import { AppContext } from "../context/AppContext";
import Spinner from "../components/loader/Spinner";
import Footer from "../components/sections/Footer";
import ContactForm from "../components/home-com/ContactForm";
import Progress from "../components/loader/Progress";
import { Link } from "react-router-dom";
import SmSpinner from "../components/loader/SmSpinner";

const Home = () => {
  const {
    scrollToDiv,
    isLoading,
    webData,
    getWebDetails,
    blogData,
    currBlogPage,
    setCurrBlogPage,
    testimoialData,
    getTestimonalsDetails,
    currTestimonialPage,
    setCurrTestmonialPage,
    projectCurrPage,
    setProjectCurrPage,
    projectLoading,
    hasMore,
    getProjectsDetails
  } = useContext(AppContext);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    window.document.title = 'Neeraj Prajapati'
    if (!testimoialData) {
      getTestimonalsDetails();
    }
  }, []);

  useEffect(() => {
    if (testimoialData && !hover) {
      const updatePagination = () => {
        setCurrTestmonialPage(
          (prevPage) => (prevPage + 1) % testimoialData.length
        );
      };

      const intervalId = setInterval(updatePagination, 3000);

      return () => clearInterval(intervalId);
    }
  }, [hover]);

  useState(() => {
    if (!webData) {
      getWebDetails();
    }
  }, []);

  useEffect(() => {
    getProjectsDetails(projectCurrPage);
  }, [projectCurrPage]);

  const handleLoadMore = () => {
    if (hasMore) {
      setProjectCurrPage(prevPage => prevPage + 1); // Load the next set of projects
    }
  };

  return (
    <div className="w-full flex flex-col bg-black relative">
      <Navbar />
      <div className="w-full h-screen relative overflow-hidden max-sm:h-[90vh]">
        <div className="absolute top-[-10px] right-0 top-bg z-10"></div>
        <div className="absolute top-0 left-[-500px] w-[900px] h-[900px] bottom-bg z-10"></div>
        <div className=" absolute top-0 right-[-600px] w-[900px] h-[900px] bottom-right-bg"></div>
        <div className="w-full h-full flex items-center justify-around pt-16 max-md:flex-col-reverse max-md:pt-20 max-sm:pt-28">
          <Info />
          <ThreeDScene />
        </div>
      </div>

      <div className="w-full min-h-[90vh] flex flex-col justify-center items-center gap-4 bg-pattern max-md:h-auto py-8">
        <div className="text-2xl font-bold text-blue-500 uppercase max-sm:text-xl">
          About me
        </div>
        <AboutMe />
      </div>

      <div className="w-full min-h-screen flex justify-center items-center bg-[#222] px-6 max-md:px-4 max-md:flex-col max-md:py-8 max-sm:py-4">
        <div className="w-[80%] flex flex-col gap-4 max-sm:w-full max-sm:gap-2">
          <div className="text-4xl font-white font-bold p-1 text-white max-lg:text-2xl max-md:text-center max-sm:text-xl">
            Work Process
          </div>
          <div className="w-full flex flex-col gap-2">
            <div className="text-md text-justify font-normal text-gray-400 max-lg:text-sm max-sm:text-xs">
              My development process starts with in-depth research and analysis to understand project goals and user needs. I then identify effective approaches to overcome obstacles and carefully organize each phase to create strong, adaptable solutions.
            </div>
            <div className="text-md text-justify font-normal text-gray-400 max-lg:text-sm max-sm:text-xs">
              Design and development are closely linked, with user experience as the top priority. I create user-friendly, visually appealing designs that enhance usability and ensure solid technical aspects. After thorough testing and optimization, I focus on continual improvements to exceed expectations and deliver lasting solutions to clients.
            </div>
          </div>
        </div>

        <div className="w-full grid grid-cols-2 grid-rows-2 gap-6 p-10 max-lg:gap-4 max-md:gap-2 max-lg:p-6 max-md:p-4 max-md:py-8 max-sm:py-4 max-sm:grid-cols-1 max-sm:gap-2 max-sm:px-0">
          <div className="w-full bg-[#333] flex flex-col justify-start items-start gap-3 p-4 rounded-lg transition duration-300 ease-in hover:bg-[#444] cursor-pointer group">
            <div className="w-[50px] h-[50px] rounded bg-blue-100 flex justify-center items-center text-blue-600 transition-all group-hover:bg-blue-600 group-hover:text-white max-md:w-[40px] max-md:h-[40px]">
              <PiNotepad className="text-3xl" />
            </div>
            <div className="w-full flex flex-col gap-2">
              <h3 className="text-white text-xl font-bold max-md:text-base max-sm:text-sm">
                1. Research
              </h3>
              <p className="text-xs font-normal text-gray-200">
                Conduct in-depth research to thoroughly understand project objectives, user needs, and goals, ensuring a strong foundation for success.
              </p>
            </div>
          </div>

          <div className="w-full bg-[#333] flex flex-col justify-start items-start gap-3 p-4 rounded-lg transition duration-300 ease-in hover:bg-[#444] cursor-pointer group translate-y-6 max-sm:translate-y-0">
            <div className="w-[50px] h-[50px] rounded bg-blue-100 flex justify-center items-center text-blue-600 transition-all group-hover:bg-blue-600 group-hover:text-white max-md:w-[40px] max-md:h-[40px]">
              <BsGraphUpArrow className="text-3xl" />
            </div>
            <div className="w-full flex flex-col gap-2">
              <h3 className="text-white text-xl font-bold  max-md:text-base max-sm:text-sm">
                2. Analyze
              </h3>
              <p className="text-xs font-normal text-gray-200">
                Evaluate data to uncover insights, define technical specs, and ensure the project’s feasibility.
              </p>
            </div>
          </div>

          <div className="w-full bg-[#333] flex flex-col justify-start items-start gap-3 p-4 rounded-lg transition duration-300 ease-in hover:bg-[#444] cursor-pointer group">
            <div className="w-[50px] h-[50px] rounded bg-blue-100 flex justify-center items-center text-blue-600 transition-all group-hover:bg-blue-600 group-hover:text-white max-md:w-[40px] max-md:h-[40px]">
              <BiEdit className="text-3xl" />
            </div>
            <div className="w-full flex flex-col gap-2">
              <h3 className="text-white text-xl font-bold max-md:text-base max-sm:text-sm">
                3. Design
              </h3>
              <p className="text-xs font-normal text-gray-200">
                Develop user-friendly designs that seamlessly integrate aesthetics with functionality, creating intuitive and efficient solutions.
              </p>
            </div>
          </div>

          <div className="w-full bg-[#333] flex flex-col justify-start items-start gap-3 p-4 rounded-lg transition duration-300 ease-in hover:bg-[#444] cursor-pointer group translate-y-6 max-sm:translate-y-0">
            <div className="w-[50px] h-[50px] rounded bg-blue-100 flex justify-center items-center text-blue-600 transition-all group-hover:bg-blue-600 group-hover:text-white max-md:w-[40px] max-md:h-[40px]">
              <CiLaptop className="text-3xl" />
            </div>
            <div className="w-full flex flex-col gap-2">
              <h3 className="text-white text-xl font-bold max-md:text-base max-sm:text-sm">
                4. Launch
              </h3>
              <p className="text-xs font-normal text-gray-200">
                Deploy optimized, secure solutions and provide ongoing support for smooth performance.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Project Section  */}
      <div id="projects" className="w-full min-h-screen flex flex-col items-center justify-center text-white py-6">
        <div className="w-full flex flex-col justify-center items-center mt-[60px] max-sm:mt-0">
          <div className="text-2xl font-bold text-blue-500 max-sm:text-xl">
            Portfolio
          </div>
          <p className="text-sm text-center text-gray-400 max-sm:text-xs max-sm:px-2">
            Showcasing My Projects: Explore the Innovations and Solutions I've Created
          </p>
        </div>
        <div className="w-full h-full p-8 max-lg:px-4">
          <Portfolios />
        </div>

        {projectLoading && (<div className="p-2"><SmSpinner/></div>)}

        {hasMore && !projectLoading &&
        (<button
          onClick={handleLoadMore}
          className="btn-bg text-lg font-white py-2 px-4 rounded font-bold max-sm:text-sm"
        >
          More Project
        </button>)}
      </div>

      <div className="w-full flex items-center justify-center py-10 bg-slate-600 max-sm:py-6">
        <div className="w-[600px] flex flex-col items-center justify-center gap-2 max-sm:w-full">
          <h2 className="w-full text-center text-3xl font-bold py-1 text-black max-sm:text-xl">
            Have a project idea? Let's bring it to life!
          </h2>
          <p className="text-sm text-gray-400 font-semibold text-center max-sm:text-xs">
            Whether you're in the brainstorming stage or have a fully developed plan, let's discuss how we can make your vision a reality. Together, we’ll craft something amazing.
          </p>
          <button onClick={() => scrollToDiv('contactForm')} className="flex items-center gap-2 text-lg font-bold text-gray-300 bg-blue-600 border border-blue-600 rounded py-2 px-4 transition duration-300 ease-in hover:bg-blue-800 hover:border-blue-800 max-sm:text-sm">
            Let’s collaborate <IoMdArrowRoundForward />
          </button>
        </div>
      </div>

      {/* Blog Section  */}
      <div className="w-full h-screen relative overflow-hidden max-sm:h-[70vh]">
        <div className="absolute top-[-10px] right-0 top-bg z-10"></div>
        <div className="absolute top-0 left-[-500px] w-[900px] h-[900px] bottom-bg z-10"></div>
        <div className=" absolute top-0 right-[-600px] w-[900px] h-[900px] bottom-right-bg"></div>
        <div className="w-full h-screen flex flex-col items-center gap-4 justify-center max-sm:pt-5 max-sm:gap-6 max-sm:h-[70vh]">
          <div className="w-full flex flex-col justify-center items-center">
            <div className="text-2xl font-bold text-blue-500 max-sm:text-xl">
              Blog
            </div>
            <p className="w-[450px] text-sm text-center text-gray-400 max-sm:w-full max-sm:px-4 max-sm:text-xs">
              Insights and Updates: Explore My Latest Thoughts and Innovations on the Blog
            </p>
          </div>
          <div className="w-full flex flex-col items-center z-20">
            <Blogs />
            {blogData && (
              <div className="flex items-center justify-center gap-1 py-4 transition duration-300 ease-in-out">
                {blogData.map((blog, index) => (
                  <div
                    key={index}
                    onClick={() => setCurrBlogPage(blog.page)}
                    className={`h-[10px] border border-b-white rounded-full cursor-pointer ${
                      currBlogPage === blog.page
                        ? "bg-white w-[30px]"
                        : "w-[10px] bg-transparent"
                    }`}
                  ></div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* What I Do Section  */}
      <div className="w-full min-h-screen flex items-center justify-around py-4 px-8 gap-8 pt-20 max-md:flex-col max-sm:px-4 max-sm:pt-5 max-sm:gap-4">
        <div className="w-full flex flex-col items-start gap-4 max-sm:gap-3">
          <h1 className="text-2xl text-white font-extrabold max-md:w-full max-md:text-center max-sm:text-lg">
            What I DO?
          </h1>
          <div className="w-full flex flex-col items-start gap-2">
            <p className="text-base text-justify text-gray-400 font-semibold max-sm:text-sm">
              I specialize in creating dynamic digital experiences that blend creativity and technical expertise. With a strong focus on User Experience (UX), I conduct thorough research and analysis to design interfaces that are intuitive and engaging. From wireframes to prototypes, I ensure that each design is tailored to meet the needs of both users and business objectives, resulting in products that are not only functional but also aesthetically appealing.
            </p>
            <p className="text-base text-justify text-gray-400 font-semibold max-sm:text-sm" style={{ textIndent: "4em" }}>
              {`In addition to design, I excel in full-stack web development, ensuring that every website or application I build is robust, scalable, and optimized for performance. Whether it's front-end design or back-end development, I deliver solutions that are secure, responsive, and tailored to the client's unique requirements. My goal is to provide seamless, user-friendly digital products that create lasting value.`}
            </p>
          </div>
          <button onClick={() => scrollToDiv('contactForm')} className="text-lg font-semibold text-gray-200 border border-blue-600 bg-blue-600 rounded-sm py-2 px-4 transition duration-300 ease-in hover:text-blue-600 hover:bg-transparent max-sm:text-base">
            Say Hello!
          </button>
        </div>

        <div className="w-full flex flex-col items-center gap-6 max-sm:gap-3">
          <div className="w-full flex flex-col items-start p-4 bg-[#222] rounded-md border-l-4 border-blue-300 transition duration-200 ease-in hover:bg-[#333] hover:border-blue-600">
            <h2 className="text-lg font-bold text-white max-sm:text-base">{`Web Development`}</h2>
            <p className="text-sm text-gray-400 font-semibold">
              With a full-stack development skillset, I build scalable, robust websites and applications. My expertise spans both front-end and back-end technologies, ensuring optimized performance, security, and responsiveness.
            </p>
          </div>
          <div className="w-full flex flex-col items-start p-4 bg-[#222] rounded-md border-l-4 border-blue-300 transition duration-200 ease-in hover:bg-[#333] hover:border-blue-600">
            <h2 className="text-lg font-bold text-white max-sm:text-base">{`User Experience (UX)`}</h2>
            <p className="text-sm text-gray-400 font-semibold">
              I focus on creating seamless and engaging experiences that prioritize user satisfaction. By understanding user behavior and conducting thorough research, I design intuitive solutions that align with both business goals and user needs.
            </p>
          </div>

          <div className="w-full flex flex-col items-start p-4 bg-[#222] rounded-md border-l-4 border-blue-300 transition duration-200 ease-in hover:bg-[#333] hover:border-blue-600">
            <h2 className="text-lg font-bold text-white max-sm:text-base">{`User Interface (UI)`}</h2>
            <p className="text-sm text-gray-400 font-semibold">
              My approach to UI design blends functionality with aesthetics. I craft visually appealing, easy-to-navigate interfaces that enhance the user experience, ensuring a balance between form and function.
            </p>
          </div>
        </div>
      </div>

      {/* Buy me a coffee */}
      <div className="w-full flex items-center justify-center py-8 max-md:py-6 max-sm:py-4">
        <div className="w-[80%] bg-[#222] rounded-xl flex justify-center items-center gap-6 py-8 max-md:py-6 max-md:w-[90%] max-sm:flex-col max-sm:gap-2">
          <h3 className="text-3xl py-1 font-bold text-gray-300 max-md:text-xl max-sm:text-lg">
            Want to support?
          </h3>
          <Link to={"https://buymeacoffee.com/neeraj_webdev"} target="_blank">
            <img
              src="https://res.cloudinary.com/do1xweis7/image/upload/v1724664972/yellow-button_gn9ory.png"
              className="w-[250px] max-md:w-[200px] max-sm:w-[150px]"
            />
          </Link>
        </div>
      </div>

      {/* Testimonial */}
      <div className="w-full min-h-[400px] flex flex-col justify-center items-center gap-4 testimonial-bg max-md:px-4 max-sm:gap-3">
        <div className="w-[450px] flex flex-col items-center gap-2 max-sm:w-full">
          <h2 className="text-3xl font-extrabold text-blue-600 py-1 max-sm:text-xl">
            Testimonial
          </h2>
          <p className="text-sm font-semibold lowercase text-gray-400 text-center max-sm:text-xs">
            Discover What Clients Are Saying About My Work and Experience Through Our Testimonials
          </p>
        </div>

        {testimoialData ? (
          <div
            className="w-[700px] max-md:w-full flex flex-col items-center justify-center gap-4 transition-all duration-300 ease-linear max-sm:gap-2"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <p className="text-center text-base font-semibold text-slate-500 max-sm:text-sm max-sm:text-justify">
              &#8220;{testimoialData[currTestimonialPage].message}&#8221;
            </p>

            <div className="w-full flex flex-col items-center">
              <p className="text-xl font-bold text-white max-sm:text-base">
                {testimoialData[currTestimonialPage].name}
              </p>
              <p className="text-base font-medium text-gray-400 max-sm:text-xs">
                {testimoialData[currTestimonialPage].profession},{" "}
                {testimoialData[currTestimonialPage].industry}
              </p>
            </div>

            <div className="flex items-center justify-center gap-1 py-4">
              {testimoialData.map((item, index) => (
                <div
                  key={index}
                  onClick={() => setCurrTestmonialPage(index)}
                  className={`h-[10px] border border-b-white rounded-full cursor-pointer ${
                    currTestimonialPage === index
                      ? "bg-white w-[30px]"
                      : "w-[10px] bg-transparent"
                  }`}
                ></div>
              ))}
            </div>
          </div>
        ) : (
          <Spinner />
        )}
      </div>

      <div id="contactForm" className="w-full min-h-[660px] flex flex-col justify-end items-center testimonial-bg relative max-lg:h-[300px] max-sm:h-[820px]">
        <div className="w-[80%] absolute bottom-24 bg-[#222] py-6 px-8 rounded-lg z-20 shadow-lg shadow-gray-700 max-lg:w-[95%] max-lg:p-4 max-md:w-[98%] max-sm:px-2 max-md:bottom-20">
          <ContactForm />
        </div>
        <Footer />
      </div>

      {isLoading && (
        <div className="fixed left-0 top-0 w-full h-screen backdrop-blur bg-[#1111] flex justify-center items-center z-50">
          <Progress />
        </div>
      )}
    </div>
  );
};

export default Home;

import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import Spinner from "../loader/Spinner";
import { IoArrowForward } from "react-icons/io5";
import { Link } from "react-router-dom";
import { IoMdHeartEmpty } from "react-icons/io";
import { IoMdHeart } from "react-icons/io";
// skills logo
import JsLogo from "../../skills-logo/Javascript.svg";
import ReactLogo from "../../skills-logo/React.png";
import PythonLogo from "../../skills-logo/Python.webp";
import CppLogo from "../../skills-logo/c++_logo.svg";
import NodeLogo from "../../skills-logo/NodeJs.svg";
import HtmlLogo from "../../skills-logo/HTML.png";
import CssLogo from "../../skills-logo/CSS.png";
import Tailwind from "../../skills-logo/Tailwind.png";
import MongoDBLogo from "../../skills-logo/MongoDB.svg";
import ExpressLogo from "../../skills-logo/Express.png";
import JavaLogo from "../../skills-logo/java.webp";
import VercelLogo from "../../skills-logo/Vercel.svg";
import GitLogo from "../../skills-logo/Git.svg";
import GithubLogo from "../../skills-logo/Github.svg";
import bashLogo from "../../skills-logo/Bash.svg";
import BootStrapLogo from "../../skills-logo/Bootstrap.svg";
import ChatJsLogo from "../../skills-logo/ChartJs.svg";
import NextJsLogo from "../../skills-logo/Next.svg";
import SqlLogo from "../../skills-logo/MySQL.svg";
import ReduxLogo from "../../skills-logo/Redux.svg";
import DockerLogo from "../../skills-logo/Docker.svg";
import GraphQlLogo from "../../skills-logo/Graphql.svg";
import TypescriptLogo from "../../skills-logo/Typescript.svg";
import { toast } from "react-toastify";

const Portfolios = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const {
    isLoading,
    projectData,
    formData,
    validateEmail,
    setIsActiveAskEmail,
    setLikeTag,
    likeProjects,
    setLikeProjects,
    portfolioLike
  } = useContext(AppContext);
  const [isActiveReadMoreBtn, setIsActiveReadMoreBtn] = useState([
    { switch: false, text: "" },
  ]);

  useEffect(() => {
    if (projectData) {
      const newArr = projectData.map((p) => ({
        switch: false,
        text: p.description.split(" ").length > 40 ? `${p.description.split(" ").slice(0, 40).join(" ")}...` : p.description
      }));
      setIsActiveReadMoreBtn(newArr);
    }
  }, [projectData]);

  const descriptionHandler = (desc, index) => {
    setIsActiveReadMoreBtn((prevState) => {
      const currData = [...prevState]; // Shallow copy of the state array
      const currentItem = { ...currData[index] }; // Deep copy of the object at the index

      currentItem.switch = !currentItem.switch;

      if (currentItem.switch === false && desc.split(" ").length > 40) {
        currentItem.text = `${desc.split(" ").slice(0, 40).join(" ")}...`;
      } else {
        currentItem.text = desc; // When `switch` is true, show the full description
      }

      currData[index] = currentItem; // Replace the modified object in the array
      return currData; // Return the updated state
    });
  };

  function likeHandler(projectId, index) {
    if (validateEmail(formData.email)) {
      if (likeProjects[index]) {
        portfolioDisLike(projectId, index);
      } else {
        portfolioLike(projectId, index);
      }
    } else {
      setLikeTag({ tag: "like project", project_id: projectId, index: index });
      setIsActiveAskEmail(true);
      toast.warn("Please enter your email");
      return;
    }
  }

  const portfolioDisLike = async (projectId, index) => {
    setLikeProjects((prevState) => {
      const currState = [...prevState];
      currState[index] = false;
      return currState;
    });

    try {
      const url = `${baseUrl}/project/dislike`;
      const bodyData = {
        email: formData.email,
        project_id: projectId,
      };

      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
      });

      const data = await response.json();

      if (data.success) {
        toast.success(data.message);
      } else {
        toast.warning(data.message);
        setLikeProjects((prevState) => {
          const currState = [...prevState];
          currState[index] = true;
          return currState;
        });
      }
    } catch (err) {
      setLikeProjects((prevState) => {
        const currState = [...prevState];
        currState[index] = true;
        return currState;
      });
      toast.error(err.message);
    }
  };

  const skillLogos = {
    javascript: <img src={JsLogo} alt="React logo" className="h-10 w-10 max-sm:h-7 max-sm:w-7" />,
    react: <img src={ReactLogo} alt="React logo" className="h-10 w-10  max-sm:h-7 max-sm:w-7" />,
    mongodb: <img src={MongoDBLogo} alt="React logo" className="h-10 w-10 max-sm:h-7 max-sm:w-7" />,
    node: <img src={NodeLogo} alt="React logo" className="h-10 w-10 max-sm:h-7 max-sm:w-7" />,
    python: <img src={PythonLogo} alt="React logo" className="h-10 w-10 max-sm:h-7 max-sm:w-7" />,
    express: <img src={ExpressLogo} alt="React logo" className="h-10 w-10 max-sm:h-7 max-sm:w-7" />,
    "c++": <img src={CppLogo} alt="React logo" className="h-10 w-10 max-sm:h-7 max-sm:w-7" />,
    tailwind: <img src={Tailwind} alt="React logo" className="h-10 w-10 max-sm:h-7 max-sm:w-7" />,
    html: <img src={HtmlLogo} alt="React logo" className="h-10 w-10 max-sm:h-7 max-sm:w-7" />,
    css: <img src={CssLogo} alt="React logo" className="h-10 w-10 max-sm:h-7 max-sm:w-7" />,
    java: <img src={JavaLogo} alt="React logo" className="h-10 w-10 max-sm:h-7 max-sm:w-7" />,
    vercel: <img src={VercelLogo} alt="React logo" className="h-10 w-10 max-sm:h-7 max-sm:w-7" />,
    git: <img src={GitLogo} alt="React logo" className="h-10 w-10 max-sm:h-7 max-sm:w-7" />,
    github: <img src={GithubLogo} alt="React logo" className="h-10 w-10 max-sm:h-7 max-sm:w-7" />,
    bash: <img src={bashLogo} alt="React logo" className="h-10 w-10 max-sm:h-7 max-sm:w-7" />,
    bootstrap: (
      <img src={BootStrapLogo} alt="React logo" className="h-10 w-10 max-sm:h-7 max-sm:w-7" />
    ),
    chart: <img src={ChatJsLogo} alt="React logo" className="h-10 w-10 max-sm:h-7 max-sm:w-7" />,
    next: <img src={NextJsLogo} alt="React logo" className="h-10 w-10 max-sm:h-7 max-sm:w-7" />,
    mysql: <img src={SqlLogo} alt="React logo" className="h-10 w-10 max-sm:h-7 max-sm:w-7" />,
    redux: <img src={ReduxLogo} alt="React logo" className="h-10 w-10 max-sm:h-7 max-sm:w-7" />,
    docker: <img src={DockerLogo} alt="React logo" className="h-10 w-10 max-sm:h-7 max-sm:w-7" />,
    graphql: <img src={GraphQlLogo} alt="React logo" className="h-10 w-10 max-sm:h-7 max-sm:w-7" />,
    typescript: (
      <img src={TypescriptLogo} alt="React logo" className="h-10 w-10 max-sm:h-7 max-sm:w-7" />
    ),
  };

  if (isLoading) {
    return (
      <div className="w-full flex justify-center items-center mt-4">
        <Spinner />
      </div>
    );
  }

  if (projectData) {
    return (
      <div className="w-full flex flex-col items-start border-2 border-slate-500 max-sm:border-none max-sm:gap-4">
        {projectData.map((project, index) => {
          return (
            <div
              key={index}
              className={`w-full h-[350px] group bg-[#333] flex items-center justify-center border outline-none border-blue-500 max-md:flex-col max-md:h-auto max-sm:rounded-md max-lg:h-[300px] ${
                index % 2 !== 0 ? "flex-row-reverse" : "flex-row"
              }`}
            >
              <div className="w-full h-full relative bg-white overflow-hidden">
                <img
                  src={project.img}
                  alt="project-img-missing"
                  className="w-full h-full object-contain transition-transform duration-300 ease-in-out group-hover:scale-110"
                />
                <div className="w-full h-full absolute top-0 left-0 transition-transform duration-300 group-hover:bg-[#0808089d]">
                  <div className="w-full h-full absolute top-0 left-0 flex-col items-center gap-2 justify-center hidden group-hover:flex">
                    <h1 className="py-2 cursive-txt font-bold text-4xl max-sm:text-2xl">
                      {project.name}
                    </h1>
                    <button
                      onClick={() => likeHandler(project._id, index)}
                      className="border border-red-500 flex items-center justify-center gap-1 text-md font-semibold text-white bg-red-500 py-1 px-2 cursor-pointer rounded transition duration-300 ease-in hover:bg-red-600 max-sm:text-sm"
                    >
                      {likeProjects[index] ? (
                        <IoMdHeart className="text-xl" />
                      ) : (
                        <IoMdHeartEmpty className="text-xl" />
                      )}
                      Like
                    </button>
                  </div>
                </div>
              </div>
              <div className="w-full h-full flex flex-col justify-center items-start gap-3 py-4 max-sm:gap-2">
                <div className="w-full flex justify-start items-center gap-4 px-8 max-lg:px-4 max-md:flex-wrap max-sm:gap-2">
                  {project.techSkills &&
                    project.techSkills.map((skill, index) => {
                      return (
                        <div key={index} className="flex items-center">
                          {skillLogos[skill.split(".").at(0).toLowerCase()]
                            ? skillLogos[skill.split(".").at(0).toLowerCase()]
                            : ""}
                        </div>
                      );
                    })}
                </div>
                <div className="w-full flex flex-col items-start px-8 max-lg:px-4">
                  <p className="text-sm text-gray-400 font-medium">
                    {project.title}
                  </p>
                  <h2 className="text-white text-lg font-semibold">
                    {project.name}
                  </h2>
                </div>

                <div className="w-full px-8 max-lg:px-4">
                  <p className="text-sm font-medium text-gray-400 text-justify max-sm:text-xs">
                    {isActiveReadMoreBtn[index]?.text}
                    {(project.description.split(" ").length > 40) && (
                      <span
                        className="cursor-pointer text-orange-500"
                        onClick={() => descriptionHandler(project.description, index)}
                      >
                        {isActiveReadMoreBtn[index]?.switch ? " show less" : "read more"}
                      </span>
                    )}
                  </p>
                </div>

                <div className="w-full flex items-center justify-start px-8 max-lg:px-4">
                  <Link
                    to={project.link}
                    target="_blank"
                    className="text-md font-bold flex items-center rounded justify-between gap-2 border py-1 px-2 border-indigo-500 text-indigo-500 transition duration-300 ease-in hover:bg-indigo-500 hover:text-white"
                  >
                    Case Study <IoArrowForward className="text-lg" />
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div>
      <div>Not Found</div>
    </div>
  );
};

export default Portfolios;

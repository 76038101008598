import React from 'react';
import Navbar from '../sections/Navbar'

const PdfViewer = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  return (
    <div className='w-full h-screen flex flex-col items-start pt-20 max-sm:pt-16 bg-black'>
      <Navbar/>
      <iframe 
        src={`${baseUrl}/files/NEERAJ_PRAJAPATI_RESUME.pdf`}
        width="100%" 
        height="100%" 
        title="PDF Viewer"
      />
    </div>
  );
};

export default PdfViewer;
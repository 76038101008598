import React from 'react'
import SocialMedia from '../home-com/SocialMedia'

const FooterSM = () => {
  return (
    <div className='w-full flex items-center justify-around py-4 bg-blue-500 max-sm:flex-col'>
        <SocialMedia/>
        <div className='text-lg font-bold text-white'>copyright &#169; 2024 <span className=' text-pink-600'>Neeraj</span></div>
    </div>
  )
}

export default FooterSM
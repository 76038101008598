import React, { useContext, useEffect, useState } from 'react'
import Navbar from '../components/sections/Navbar';
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import SmSpinner from '../components/loader/SmSpinner';
import { TiWarning } from "react-icons/ti";
import { MdReportGmailerrorred } from "react-icons/md";
import { AppContext } from '../context/AppContext';
import { toast } from 'react-toastify';
import FooterSM from '../components/sections/FooterSM';

const Login = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const {AuthUser} = useContext(AppContext);
    const navigate = useNavigate();
    const [isVisiablePass, setIsVisiablePass] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [emailError, setEmailError] = useState({ error: false, message: '' });
    const [passwordError, setPasswordError] = useState({ error: false, message: '' });
    const [otpError, setOtpError] = useState({ error: false, message: '' });
    const [isVerifyOtp, setIsVerifyOtp] = useState(false);
    const [otpCode, setOtpCode] = useState('');
    const [serverOpt, setServerOtp] = useState('');
    const [userToken, setUserToken] = useState('');
    const [formData, setFormData] = useState({
        email:'',
        password:''
    });

    useEffect(() => {
        window.document.title = 'Login'
    },[]);

    function inputHandler(event) {
        setError('');
        if (event.target.name === 'email') {
            setEmailError({ error: false, message: '' });
        }
        if (event.target.name === 'password') {
            setPasswordError({ error: false, message: '' });
        }
        if (event.target.name === 'otp') {
            setOtpCode(event.target.value);
            setOtpError({ error: false, message: '' });
            return;
        }
        setFormData((prevData) => ({
            ...prevData,
            [event.target.name]: event.target.value
        }));
    }

    const isFormFilled = () => {
        return Object.values(formData).every(value => value.trim() !== '');
    };

    const loginHandler = async () => {
        if(!isFormFilled()){
            setError('Please fill out all fields.');
            return;
        }
        try {
            setIsLoading(true);
            const url = `${baseUrl}/login`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();

            if (response.ok && data.success === true) {
                if(data.admin){
                    await verificationOtp(data.user_id);
                    setUserToken(data.token);
                    setIsVerifyOtp(true);
                } else{
                    toast.warning('Not Logged In - This only for Admin');
                }
            } 
            else {
                if (data.tag === 'email') {
                    setEmailError({ error: true, message: data.message });
                } else if (data.tag === 'password') {
                    setPasswordError({ error: true, message: data.message });
                } else {
                    setError(data.message || 'An unexpected error occurred. Please try again.');
                }
            }
        } catch (err) {
            console.error('Error:', err);
            setError('Failed to sign in. Please check your network connection or try again later.');
        } finally {
            setIsLoading(false);
        }
    }

    const verificationOtp = async (userId) => {
        try{
            setIsLoading(true)
            const url = `${baseUrl}/otp/verify`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({user_id:userId}),
            });
            const data = await response.json();

            if (response.ok && data.success === true) {
                setServerOtp(data.otp);
                toast.success(data.message);
            } 
            else {
                if (data.tag === 'email') {
                    setEmailError({ error: true, message: data.message });
                } else if (data.tag === 'password') {
                    setPasswordError({ error: true, message: data.message });
                } else {
                    setError(data.message || 'An unexpected error occurred. Please try again.');
                }
            }
        } catch (err) {
            console.error('Error:', err);
            setError('Failed to verify otp. Please check your network connection or try again later.');
        } finally {
            setIsLoading(false);
        }
    }

    const verifyOtp = async() => {
        try{
            setIsLoading(true);
            if((otpCode === serverOpt) && (userToken !== '')){
                localStorage.setItem('NPToken', userToken);
                await AuthUser();
                toast.success('User logged in successfully');
            }
            else{
                toast.warning('wrong otp');
                setOtpError({error:true, message:'worng otp'});
            }
        }
        catch(err){
            setError(err.message);
        }
        finally{
            setIsLoading(false);
        }
    }

  return (
    <div className='w-full min-h-screen bg-black flex flex-col items-center gap-4 justify-between'>
        <Navbar/>
        <div className='w-full h-full flex justify-around items-center box-content pt-[80px] max-sm:flex-col'>
            <div className='w-[400px] max-md:w-[300px] max-sm:w-[80%]'>
                <img src='https://res.cloudinary.com/do1xweis7/image/upload/v1723816607/loginPage_na8syd.png' alt='3D-img' className='w-full'/>
            </div>
            <div className='w-[500px] flex flex-col text-white bg-[#111] py-6 px-8 max-sm:p-4 rounded-xl gap-6 max-sm:gap-4 max-md:w-[400px] max-sm:w-full'>
                <div className='w-full flex flex-col gap-1'>
                    <h1 className='text-xl font-bold text-white max-md:text-lg'>{isVerifyOtp ? 'Verify user' : 'Login'}</h1>
                    <p className='text-md font-semibold text-gray-400 max-md:text-sm'>{isVerifyOtp ? 'Check your email for the OTP' : 'Sign in to your account to continue'}</p>
                </div>

                <div className='w-full flex flex-col gap-4 max-md:gap-3'>
                    {!isVerifyOtp && (<label className='w-full flex flex-col gap-1'>
                        <p className='text-md font-semibold text-gray-500 max-md:text-sm'>Email address</p>
                        <input
                            type='email'
                            name='email'
                            value={formData.email}
                            onChange={inputHandler}
                            placeholder='example@gmail.com'
                            className={`text-lg max-md:text-base font-semibold py-2 px-4 max-sm:px-2 bg-[#333] text-white placeholder:text-gray-500 rounded outline-none border-2 ${emailError.error ? 'border-red-500' : 'border-transparent transition duration-300 ease-in hover:border-blue-300 focus:border-blue-500'}`}
                        />
                        {
                            emailError.error && (
                                <div className='w-full flex items-center justify-start gap-2 text-red-500 text-sm font-medium max-sm:text-xs'>
                                    <MdReportGmailerrorred className='text-lg max-sm:text-base text-orange-500' />
                                    {emailError.message}
                                </div>
                            )
                        }
                    </label>)}

                    {!isVerifyOtp && (<label className='w-full flex flex-col gap-1 relative'>
                        <div className='w-full flex items-center justify-between'>
                            <p className='text-md max-md:text-sm font-semibold text-gray-500'>Password</p>
                            <p onClick={() => navigate('/forget-password')} className='text-md max-md:text-sm font-semibold text-blue-500 cursor-pointer transition duration-200 ease-in hover:underline'>Lost Password?</p>
                        </div>
                        <input
                            type={isVisiablePass ? 'text' : 'password'}
                            name='password'
                            value={formData.password}
                            onChange={inputHandler}
                            placeholder='password'
                            className={`text-lg max-md:text-base font-semibold py-2 max-sm:px-2 pl-4 pr-12 bg-[#333] text-white placeholder:text-gray-500 rounded outline-none border-2 ${passwordError.error ? 'border-red-500' : 'border-transparent transition duration-300 ease-in hover:border-blue-300 focus:border-blue-500'}`}
                        />
                        <div className='absolute top-9 right-2 text-3xl max-md:text-xl cursor-pointer text-gray-500' onClick={() => setIsVisiablePass(!isVisiablePass)}>
                            {isVisiablePass ? (<IoMdEyeOff />) : (<IoMdEye />)}
                        </div>
                        {
                            passwordError.error && (
                                <div className='w-full flex items-center justify-start gap-2 text-red-500 text-sm font-medium max-sm:text-xs'>
                                    <MdReportGmailerrorred className='text-lg text-orange-500' />
                                    {passwordError.message}
                                </div>
                            )
                        }
                    </label>)}

                    {isVerifyOtp && (<label className='w-full flex flex-col gap-1'>
                        <p className='text-md max-md:text-sm font-semibold text-gray-500'>OTP</p>
                        <input
                            type='text'
                            name='otp'
                            value={otpCode}
                            onChange={inputHandler}
                            placeholder='enter otp'
                            className={`text-lg max-md:text-base max-sm:px-2 font-semibold py-2 px-4 bg-[#333] text-white placeholder:text-gray-500 rounded outline-none border-2 ${otpError.error ? 'border-red-500' : 'border-transparent transition duration-300 ease-in hover:border-blue-300 focus:border-blue-500'}`}
                        />
                        {
                            otpError.error && (
                                <div className='w-full flex items-center justify-start gap-2 text-red-500 text-sm font-medium max-sm:text-xs'>
                                    <MdReportGmailerrorred className='text-lg text-orange-500' />
                                    {otpError.message}
                                </div>
                            )
                        }
                    </label>)}

                    {error !== '' && (
                        <div className='w-full flex items-center justify-start gap-2 py-1 px-3 text-md max-md:text-sm max-sm:text-xs text-red-500 border border-red-500 font-semibold'>
                            <TiWarning className='text-xl max-md:lg text-yellow-500' />
                            <p>{error}</p>
                        </div>
                    )}

                    {!isVerifyOtp && (<div className='w-full flex justify-start'>
                        <button className='text-lg max-md:text-base flex items-center gap-4 font-extrabold text-white border-2 border-blue-500 bg-blue-500 py-1 px-4 rounded-3xl transition duration-300 ease-in hover:bg-transparent' onClick={loginHandler}>{isLoading && (<SmSpinner/>)}Sign in</button>
                    </div>)}

                    {isVerifyOtp && (<div className='w-full flex justify-start'>
                        <button className='text-lg max-md:text-base flex items-center gap-4 font-extrabold text-white border-2 border-blue-500 bg-blue-500 py-1 px-4 rounded-3xl transition duration-300 ease-in hover:bg-transparent' onClick={verifyOtp}>{isLoading && (<SmSpinner/>)}Verify otp</button>
                    </div>)}
                </div>

                {!isVerifyOtp && (<div className='w-full flex items-center justify-start gap-2'>
                    <p className='text-md max-md:text-sm text-[#444] font-medium'>Not registered?</p>
                    <p className='text-md max-md:text-sm font-semibold text-blue-500 cursor-pointer transition duration-200 ease-in hover:underline' onClick={() => navigate('/signup')}>Create account</p>
                </div>)}
            </div>
        </div>

        <FooterSM/>
    </div>
  )
}

export default Login
import React, { useContext, useState, useRef } from "react";
import { IoClose } from "react-icons/io5";
import { FaRegImages } from "react-icons/fa6";
import { MdError } from "react-icons/md";
import { VscVerifiedFilled } from "react-icons/vsc";
import { UserContext } from "../../context/UserContext";
import { toast } from "react-toastify";
import SmSpinner from "../loader/SmSpinner";

const PostBlog = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL; 
  const { setIsActiveBlogForm } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [imageName, setImageName] = useState("");
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [error, setError] = useState("");
  const [submitMessage, setSubmitMessage] = useState("");

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    link: "",
  });
  
  const [hashTags, setHashTags] = useState([""]);

  const inputHandler = (event, index) => {
    if (event.target.name === "tag") {
      const newTags = [...hashTags];
      newTags[index] = event.target.value;
      setHashTags(newTags);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [event.target.name]: event.target.value,
      }));
    }
  };

  const handleAddTags = () => {
    setHashTags([...hashTags, ""]);
  };

  const handleRemoveTag = (index) => {
    const newTags = hashTags.filter((_, i) => i !== index);
    setHashTags(newTags);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedImage(reader.result);
      };
      reader.readAsDataURL(file);
      setImageName(file.name);
    }
  };

  const handleChangeImage = () => {
    fileInputRef.current && fileInputRef.current.click();
  };

  const uploadBlog = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("NPToken");

      if (!token) {
        throw new Error("Token is missing. Please log in again.");
      }

      const formDataX = new FormData();
      formDataX.append("token", token);
      formDataX.append("title", formData.title);
      formDataX.append("description", formData.description);
      formDataX.append("link", formData.link);

      hashTags.forEach((tag) => {
        formDataX.append("hashTags[]", tag); // Notice the "[]"
      });
      if (file) {
        formDataX.append("file", file);
      }

      const url = `${baseUrl}/blog/create`;
      const response = await fetch(url, {
        method: "POST",
        body: formDataX,
      });

      const data = await response.json();

      if (response.ok && data.success) {
        toast.success(data.message);
        setError("");
        setSubmitMessage(data.message);
        setTimeout(() => {
          setIsActiveBlogForm(false);
        }, 5000);
      } else {
        setError(data.message || "An error occurred");
        setSubmitMessage("");
      }
    } catch (err) {
      setSubmitMessage("");
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-[600px] flex flex-col items-center gap-4 py-4 px-10 my-4 bg-white max-sm:w-full">
      <div className="w-full flex justify-between items-center text-xl">
        <h1 className="font-bold">Create New Blog Post</h1>
        <IoClose
          className="text-2xl cursor-pointer"
          onClick={() => setIsActiveBlogForm(false)}
        />
      </div>

      <label className="w-full flex flex-col gap-1">
        <p className="w-full text-md font-medium">
          Title<span className="text-lg text-red-500">*</span>
        </p>
        <input
          type="text"
          placeholder="blog title"
          required
          name="title"
          value={formData.title}
          onChange={inputHandler}
          className="w-full text-md font-semibold border-none bg-gray-200 py-2 px-4 outline-2 rounded-sm focus:outline-green-600"
        />
      </label>

      <label className="w-full flex flex-col gap-1">
        <p className="w-full text-md font-medium">
          Paste prev blog link<span className="text-lg text-red-500">*</span>
        </p>
        <input
          type="text"
          placeholder="www.your_app.com"
          required
          name="link"
          value={formData.link}
          onChange={inputHandler}
          className="w-full text-md font-semibold border-none bg-gray-200 py-2 px-4 outline-2 rounded-sm focus:outline-green-600"
        />
      </label>

      <div className="w-full flex flex-col gap-2">
        <p className="text-md font-medium">
          Tag<span className="text-lg text-red-500">*</span>
        </p>
        {hashTags.map((tag, index) => (
          <div key={index} className="w-full flex items-center justify-between">
            <input
              type="text"
              placeholder="e.g. #love #coding"
              required
              name="tag"
              value={tag}
              onChange={(event) => inputHandler(event, index)}
              className="w-full text-md font-semibold border-none bg-gray-200 py-2 px-4 outline-2 rounded-sm focus:outline-green-600"
            />
            <button
              type="button"
              onClick={() => handleRemoveTag(index)}
              className="text-red-500 text-md font-semibold px-2"
            >
              Remove
            </button>
          </div>
        ))}
        <button
          type="button"
          onClick={handleAddTags}
          className="text-blue-500 mt-2"
        >
          Add Another Tag
        </button>
      </div>

      <label className="w-full flex flex-col gap-1">
        <p className="text-md font-medium">
          Description<span className="text-lg text-red-500">*</span>
        </p>
        <textarea
          rows={2}
          name="description"
          value={formData.description}
          onChange={inputHandler}
          placeholder="Write a description"
          className="w-full text-md font-semibold border-none bg-gray-200 py-2 px-4 outline-2 rounded-sm focus:outline-green-600"
        />
      </label>

      <div
        className="w-full flex items-center justify-start gap-4 px-4 py-2 border-2 border-dashed rounded-md border-gray-300 cursor-pointer"
        onClick={() => fileInputRef.current && fileInputRef.current.click()}
      >
        <FaRegImages
          className={`text-gray-400 ${uploadedImage ? "text-4xl" : "text-2xl"}`}
        />
        {uploadedImage ? (
          <div className="w-full flex justify-between items-center">
            <div className="w-full flex flex-col items-start justify-center">
              <p>{imageName}</p>
              <button
                className="text-sm border font-semibold rounded py-1 px-2 bg-slate-400 border-black"
                onClick={handleChangeImage}
              >
                Change Image
              </button>
            </div>
            <img
              src={uploadedImage}
              alt="Uploaded"
              className="w-[80px] max-h-[80px] object-cover"
            />
          </div>
        ) : (
          <p className="text-md font-semibold text-gray-400">
            upload or select image
          </p>
        )}
        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          onChange={handleImageUpload}
          className="hidden"
        />
      </div>

      {error !== "" && (
        <div className="w-full flex items-center justify-start border border-red-600 bg-red-200 py-1 px-2 gap-2">
          <MdError className="text-xl" />
          <div className="text-md font-semibold">{error}</div>
        </div>
      )}

      <button
        onClick={uploadBlog}
        className="w-full text-lg font-bold text-white rounded uppercase flex items-center justify-center gap-4 py-2 bg-slate-600 transition duration-300 ease-in-out hover:bg-slate-800"
      >
        Blog Post {isLoading && <SmSpinner />}
      </button>

      {submitMessage !== "" && (
        <div className="w-full flex items-center justify-start border border-green-600 bg-green-200 py-1 px-2 gap-2">
          <VscVerifiedFilled className="text-xl" />
          <div className="text-md font-semibold">{submitMessage}</div>
        </div>
      )}
    </div>
  );
};

export default PostBlog;

import React, { useEffect, useState } from 'react';
import Navbar from '../components/sections/Navbar';
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import SmSpinner from '../components/loader/SmSpinner';
import { TiWarning } from "react-icons/ti";
import { MdReportGmailerrorred } from "react-icons/md";

const ForrgetPass = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const navigate = useNavigate();
    const [isVerify, setIsVerify] = useState(false);
    const [isSendOtp, setIsSendOtp] = useState(false);
    const [isVisiablePass, setIsVisiablePass] = useState(false);
    const [isOtpPass, setIsOtpPass] = useState(false);
    const [otpError, setOtpError] = useState({ error: false, message: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [emailError, setEmailError] = useState({ error: false, message: '' });
    const [passwordError, setPasswordError] = useState({ error: false, message: '' });
    const [formData, setFormData] = useState({
        email: '',
        new_password: '',
        conf_password: '',
        otp:''
    });

    useEffect(() => {
        window.document.title = 'forgot password'
    },[]);

    function inputHandler(event) {
        setError('');
        if (event.target.name === 'email') {
            setEmailError({ error: false, message: '' });
        }
        if (event.target.name === 'password') {
            setPasswordError({ error: false, message: '' });
        }
        setFormData((prevData) => ({
            ...prevData,
            [event.target.name]: event.target.value
        }));
    }

    return (
        <div className='w-full min-h-screen bg-black flex flex-col'>
            <Navbar />
            <div className='w-full h-[80vh] flex justify-around items-center box-content pt-[75px]'>
                <div className='w-[400px]'>
                    <img src='https://res.cloudinary.com/do1xweis7/image/upload/v1723816607/loginPage_na8syd.png' alt='3D-img' className='w-full' />
                </div>
                <div className='w-[550px] flex flex-col text-white bg-[#111] py-6 px-8 rounded-xl gap-6'>
                    <div className='w-full flex flex-col gap-1'>
                        <h1 className='text-xl font-bold text-white'>forgot password<span className="text-xl text-blue-500">.</span></h1>
                    </div>

                    <div className='w-full flex flex-col gap-4'>
                        {!isVerify && (<label className='w-full flex flex-col gap-1'>
                            <p className='text-md font-semibold text-gray-500'>Email address</p>
                            <input
                                type='email'
                                name='email'
                                value={formData.email}
                                onChange={inputHandler}
                                placeholder='example@gmail.com'
                                className={`text-lg font-semibold py-2 px-4 bg-[#333] text-white placeholder:text-gray-500 rounded outline-none border-2 ${emailError.error ? 'border-red-500' : 'border-transparent transition duration-300 ease-in hover:border-blue-300 focus:border-blue-500'}`}
                            />
                            {
                                emailError.error && (
                                    <div className='w-full flex items-center justify-start gap-2 text-red-500 text-sm font-medium'>
                                        <MdReportGmailerrorred className='text-lg text-orange-500' />
                                        {emailError.message}
                                    </div>
                                )
                            }
                        </label>)}

                        {isSendOtp &&(<label className={`w-full flex flex-col gap-1 relative`}> 
                            <p className='text-md font-semibold text-gray-500'>{`Verification code(OTP)`}</p>
                            <input
                                type={isOtpPass ? 'text' : 'password'}
                                name='otp'
                                value={formData.otp}
                                onChange={inputHandler}
                                placeholder='enter code'
                                className={`text-lg font-semibold py-2 pl-4 pr-12 bg-[#333] text-white placeholder:text-gray-500 rounded outline-none border-2 ${otpError.error ? 'border-red-500' : 'border-transparent transition duration-300 ease-in hover:border-blue-300 focus:border-blue-500'}`}
                            />
                            <div className={`absolute top-9 right-2 text-3xl cursor-pointer text-gray-500`} onClick={() => setIsOtpPass(!isOtpPass)}> 
                                {isOtpPass ? (<IoMdEyeOff />) : (<IoMdEye />)}
                            </div>
                            {
                                otpError.error && (
                                    <div className='w-full flex items-center justify-start gap-2 text-red-500 text-sm font-medium'>
                                        <MdReportGmailerrorred className='text-lg text-orange-500' />
                                        {otpError.message}
                                    </div>
                                )
                            }
                        </label>)}

                        {isVerify && (<label className='w-full flex flex-col gap-1 relative'>
                            <p className='text-md font-semibold text-gray-500'>New password</p>
                            <input
                                type={isVisiablePass ? 'text' : 'password'}
                                name='new_password'
                                value={formData.new_password}
                                onChange={inputHandler}
                                placeholder='enter new password'
                                className={`text-lg font-semibold py-2 pl-4 pr-12 bg-[#333] text-white placeholder:text-gray-500 rounded outline-none border-2 ${passwordError.error ? 'border-red-500' : 'border-transparent transition duration-300 ease-in hover:border-blue-300 focus:border-blue-500'}`}
                            />
                            <div className='absolute top-9 right-2 text-3xl cursor-pointer text-gray-500' onClick={() => setIsVisiablePass(!isVisiablePass)}>
                                {isVisiablePass ? (<IoMdEyeOff />) : (<IoMdEye />)}
                            </div>
                            {
                                passwordError.error && (
                                    <div className='w-full flex items-center justify-start gap-2 text-red-500 text-sm font-medium'>
                                        <MdReportGmailerrorred className='text-lg text-orange-500' />
                                        {passwordError.message}
                                    </div>
                                )
                            }
                        </label>)}

                        {isVerify && (<label className='w-full flex flex-col gap-1 relative'>
                            <p className='text-md font-semibold text-gray-500'>Conform password</p>
                            <input
                                type={isVisiablePass ? 'text' : 'password'}
                                name='conf_password'
                                value={formData.conf_password}
                                onChange={inputHandler}
                                placeholder='password'
                                className={`text-lg font-semibold py-2 pl-4 pr-12 bg-[#333] text-white placeholder:text-gray-500 rounded outline-none border-2 ${passwordError.error ? 'border-red-500' : 'border-transparent transition duration-300 ease-in hover:border-blue-300 focus:border-blue-500'}`}
                            />
                            <div className='absolute top-9 right-2 text-3xl cursor-pointer text-gray-500' onClick={() => setIsVisiablePass(!isVisiablePass)}>
                                {isVisiablePass ? (<IoMdEyeOff />) : (<IoMdEye />)}
                            </div>
                            {
                                passwordError.error && (
                                    <div className='w-full flex items-center justify-start gap-2 text-red-500 text-sm font-medium'>
                                        <MdReportGmailerrorred className='text-lg text-orange-500' />
                                        {passwordError.message}
                                    </div>
                                )
                            }
                        </label>)}

                        {error !== '' && (
                            <div className='w-full flex items-center justify-start gap-2 py-1 px-3 text-md text-red-500 border border-red-500 font-semibold'>
                                <TiWarning className='text-xl text-yellow-500' />
                                <p>{error}</p>
                            </div>
                        )}

                        <div className='w-full flex justify-start'>
                            <button className='text-lg flex items-center gap-4 font-extrabold text-white border-2 border-blue-500 bg-blue-500 py-1 px-4 rounded-3xl transition duration-300 ease-in hover:bg-transparent'>
                                {isLoading && (<SmSpinner />)}
                                {isSendOtp ? 'Verify OTP' : 'Send OTP'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForrgetPass;
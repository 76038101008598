import React, { useContext, useEffect } from 'react';
import Navbar from '../components/sections/Navbar';
import FooterSM from '../components/sections/FooterSM';
import { AppContext } from '../context/AppContext';
import Progress from '../components/loader/Progress';
import ServicesCard from '../components/home-com/ServicesCard';

const Services = () => {
  const {isLoading} = useContext(AppContext);

  useEffect(() => {
    window.document.title = 'Services'
  },[]);

  return (
    <div className='w-full flex flex-col bg-black relative'>
        <Navbar/>
        <div className='w-full flex flex-col items-center gap-6 py-6 mt-12 max-md:gap-4 max-sm:gap-4'>
          <div className='flex flex-col items-center'>
            <h2 className='text-2xl font-bold uppercase text-blue-300 max-sm:text-lg'>My services</h2>
            <div className='flex items-center gap-1'>
              <div className='w-[30px] h-[2px] bg-blue-600'></div>
              <div className='cursive-text text-xl font-bold text-orange-500 max-sm:text-base'>what i provide</div>
              <div className='w-[30px] h-[2px] bg-blue-600'></div>
            </div>
          </div>

          <ServicesCard/>

        </div>
        <FooterSM/>

        {isLoading && (
          <div className='fixed left-0 top-0 w-full h-screen backdrop-blur bg-[#1111] flex justify-center items-center z-50'>
            <Progress/>
          </div>
        )}
    </div>
  )
}

export default Services
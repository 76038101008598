import React, { useRef, useEffect, useContext } from 'react';
import Typed from "typed.js";
import { AppContext } from '../../context/AppContext';
import { useNavigate } from 'react-router-dom';

const Info = () => {
  const typedElement = useRef(null);
  const { webData, projectData } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (typedElement.current) {
      const options = {
        strings: [
          "Full Stack Developer",
          "Web Developer",
          "Frontend Developer",
          "Backend Developer",
          "Software Developer",
          "Coder",
        ],
        loop: true,
        typeSpeed: 100,
        backSpeed: 100,
        backDelay: 1000,
      };

      // Initialize Typed.js on the referenced element
      const typed = new Typed(typedElement.current, options);

      // Cleanup effect (destroys the instance of Typed.js)
      return () => {
        typed.destroy();
      };
    }
  }, [webData, projectData]);

  return (
    <div className='w-[650px] flex items-start flex-col gap-4 p-8 z-10 max-lg:gap-1 max-lg:w-[600px] max-md:w-full max-sm:px-4 max-sm:py-4 max-sm:gap-2'>
      <div className='w-full flex flex-col'>
        <div className='text-3xl font-semibold text-gray-400 py-1 max-sm:text-xl max-sm:py-0'>Hello, I'm</div>
        <div className='text-4xl font-bold text-white py-1 max-lg:text-2xl max-sm:py-0'>{webData?.name}</div>
      </div>
      <div className='w-full h-[60px] text-3xl font-medium text-gray-400 max-lg:text-xl max-lg:h-[50px] max-sm:h-auto'>I'm a <span ref={typedElement} className="role text-5xl font-bold text-blue-500 max-lg:text-3xl max-sm:text-xl" /></div>
      <div className='text-md font-normal text-gray-300 max-sm:text-justify max-sm:text-sm'>
        {webData?.summary}
      </div>

      <button onClick={() => navigate('/services')} className='text-xl font-semibold text-gray-300 py-2 px-4 border border-blue-500 btn-bg rounded max-sm:text-base max-sm:py-1 max-sm:px-2'>My Services</button>

      <div className=' h-[80px] flex justify-between items-center text-white bg-[#333] rounded mt-6'>
        <div className=' h-full flex flex-col items-center justify-center px-4'>
          <div className='text-lg font-bold text-white max-sm:text-base'>{`${webData?.totalExperience}+ Y`}</div>
          <div className='text-md font-medium text-center text-gray-400 max-sm:text-xs'>Experience</div>
        </div>
        <div className='h-full flex flex-col items-center justify-center border-l-2 border-[#111] px-4'>
          <div className='text-lg font-bold text-white max-sm:text-base'>{`${webData?.projectCounts}+`}</div>
          <div className='text-md font-medium text-center text-gray-400 max-sm:text-xs'>Live Projects</div>
        </div>
        <div className='h-full flex flex-col items-center justify-center border-l-2 border-[#111] px-4'>
          <div className='text-lg font-bold text-white max-sm:text-base'>{`${webData?.blogCounts}`}</div>
          <div className='text-md font-medium text-center text-gray-400 max-sm:text-xs'>Total Blogs</div>
        </div>
      </div>
    </div>
  );
}

export default Info;

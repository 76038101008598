import React, { useEffect, useState } from 'react';
import { PiPaperPlaneTiltFill } from "react-icons/pi";
import SmSpinner from '../loader/SmSpinner';
import { useLocation } from 'react-router-dom';
import { MdError } from "react-icons/md";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { toast } from 'react-toastify';

const AddTestimonial = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL; 
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [submitMessage, setSubmitMessage] = useState('');
  const [formData, setFormData] = useState({
    name:'',
    email:'',
    message:'',
    feedback:'',
    profession:'',
    industry:'',
    web_link:''
  });

  useEffect(() => {
    const fullUrl = `${window.location.origin}`;
    setFormData((prevState) => ({
      ...prevState,
      web_link: fullUrl
    }));
  }, [location.pathname]);

  function inputHandler(event){
    setFormData((prevState) => ({
      ...prevState,
      [event.target.name] : event.target.value
    }))
  }

  const submitHandler = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("NPToken");
      const url = `${baseUrl}/testimoinal/create`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();

      if (response.ok && data.success) {
        toast.success(data.message);
        setError("");
        setSubmitMessage(data.message);
        setFormData((prevState) => ({
          ...prevState,
          name:'',
          email:'',
          message:'',
          feedback:'',
          profession:'',
          industry:''
        }));
      } else {
        setError(data.message || "An error occurred");
        setSubmitMessage("");
      }
    } catch (err) {
      setSubmitMessage("");
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  }
  
  return (
    <div className='w-full flex flex-col items-center gap-4'>
      <h1 className='text-2xl font-bold text-white'>Add Client Reviews</h1>
      <div className='w-full flex justify-between items-start gap-8'>
        <div className='w-full flex flex-col gap-6'>
          <label className='w-full flex flex-col items-start gap-1'>
            <p className='text-base font-semibold text-blue-300'>Name</p>
            <input
              type='text'
              name='name'
              placeholder='your name'
              value={formData.name}
              onChange={inputHandler}
              className='text-lg font-semibold w-full py-2 px-4 text-black bg-blue-300 outline-none border-2 border-transparent placeholder:text-gray-600 rounded transition duration-300 ease-in focus:border-blue-600'
            />
          </label>

          <label className='w-full flex flex-col items-start gap-1'>
            <p className='text-base font-semibold text-blue-300'>Email</p>
            <input
              type='email'
              name='email'
              placeholder='e.g. abc@gmail.com'
              value={formData.email}
              onChange={inputHandler}
              className='text-lg font-semibold w-full py-2 px-4 text-black bg-blue-300 outline-none border-2 border-transparent placeholder:text-gray-600 rounded transition duration-300 ease-in focus:border-blue-600'
            />
          </label>

          <label className='w-full flex flex-col items-start gap-1'>
            <p className='text-base font-semibold text-blue-300'>Profession</p>
            <input
              type='text'
              name='profession'
              placeholder='e.g. Software Developer'
              value={formData.profession}
              onChange={inputHandler}
              className='text-lg font-semibold w-full py-2 px-4 text-black bg-blue-300 outline-none border-2 border-transparent placeholder:text-gray-600 rounded transition duration-300 ease-in focus:border-blue-600'
            />
          </label>

          <label className='w-full flex flex-col items-start gap-1'>
            <p className='text-base font-semibold text-blue-300'>Work Here</p>
            <input
              type='text'
              name='industry'
              placeholder='e.g. Google'
              value={formData.industry}
              onChange={inputHandler}
              className='text-lg font-semibold w-full py-2 px-4 text-black bg-blue-300 outline-none border-2 border-transparent placeholder:text-gray-600 rounded transition duration-300 ease-in focus:border-blue-600'
            />
          </label>
        </div>

        <div className='w-full flex flex-col gap-6'>
          <label className='w-full flex flex-col items-start gap-1'>
            <p className='text-base font-semibold text-blue-300'>Message</p>
            <textarea
              type='text'
              name='message'
              placeholder='Write important message'
              value={formData.message}
              onChange={inputHandler}
              rows={3}
              className='text-lg font-semibold w-full py-2 px-4 text-black bg-blue-300 outline-none border-2 border-transparent placeholder:text-gray-600 rounded transition duration-300 ease-in focus:border-blue-600'
            />
          </label>

          <label className='w-full flex flex-col items-start gap-1'>
            <p className='text-base font-semibold text-blue-300'>Feedback</p>
            <textarea
              type='text'
              name='feedback'
              placeholder='suggest me'
              value={formData.feedback}
              onChange={inputHandler}
              rows={3}
              className='text-lg font-semibold w-full py-2 px-4 text-black bg-blue-300 outline-none border-2 border-transparent placeholder:text-gray-600 rounded transition duration-300 ease-in focus:border-blue-600'
            />
          </label>

          {
            error !== '' && (
              <div className='w-full flex items-center justify-start gap-2 py-1 px-4 border border-red-500 text-red-500 font-semibold'>
                <MdError/>
                <p>{error}</p>
              </div>
            )
          }

          {
            submitMessage !== '' && (
              <div className='w-full flex items-center justify-start gap-2 py-1 px-4 border border-green-500 text-green-500 font-semibold'>
                <RiVerifiedBadgeFill/>
                <p>{submitMessage}</p>
              </div>
            )
          }

          <button 
            onClick={() => submitHandler()}
            className='text-lg font-bold w-full flex items-center justify-center gap-2 py-2 px-4 text-white bg-blue-600 outline-none border-2 border-transparent rounded transition duration-300 ease-in hover:bg-blue-800'
          >Submit {isLoading ? (<SmSpinner/>) : (<PiPaperPlaneTiltFill/>)}</button>
        </div>
      </div>
    </div>
  )
}

export default AddTestimonial
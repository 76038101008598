import React, { useContext, useEffect } from 'react'
import { AppContext } from '../../context/AppContext';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter, FaGithub } from "react-icons/fa";
import { TbBrandFiverr } from "react-icons/tb";
import { FaUpwork } from "react-icons/fa6";
import { Link } from 'react-router-dom';

const SocialMedia = () => {
    const {webData, getWebDetails} = useContext(AppContext);
    useEffect(() => {
        if(!webData){
            getWebDetails();
        }
    },[]);
  return (
    <div className='flex items-center justify-center gap-4 max-lg:gap-2 max-sm:justify-evenly'>
        <div>
            <Link
                to={webData?.facebook_link}
                target='_blank'
                className='w-[40px] h-[40px] flex justify-center items-center rounded-md bg-[#333] cursor-pointer transition duration-300 ease-in text-blue-500 hover:bg-blue-600 hover:text-white max-sm:w-[30px] max-sm:h-[30px]'
            ><FaFacebookF className='text-xl'/></Link>
        </div>
        <div>
            <Link
                to={webData?.instagram_link}
                target='_blank'
                className='w-[40px] h-[40px] flex justify-center items-center rounded-md bg-[#333] cursor-pointer transition duration-300 ease-in text-blue-500 hover:bg-blue-600 hover:text-white max-sm:w-[30px] max-sm:h-[30px]'
            ><FaInstagram className='text-xl'/></Link>
        </div>
        <div>
            <Link
                to={webData?.linkedin_link}
                target='_blank'
                className='w-[40px] h-[40px] flex justify-center items-center rounded-md bg-[#333] cursor-pointer transition duration-300 ease-in text-blue-500 hover:bg-blue-600 hover:text-white max-sm:w-[30px] max-sm:h-[30px]'
            ><FaLinkedinIn className='text-xl'/></Link>
        </div>
        <div>
            <Link
                to={webData?.github_link}
                target='_blank'
                className='w-[40px] h-[40px] flex justify-center items-center rounded-md bg-[#333] cursor-pointer transition duration-300 ease-in text-blue-500 hover:bg-blue-600 hover:text-white max-sm:w-[30px] max-sm:h-[30px]'
            ><FaGithub className='text-xl'/></Link>
        </div>
        <div>
            <Link
                to={webData?.twitter_link}
                target='_blank'
                className='w-[40px] h-[40px] flex justify-center items-center rounded-md bg-[#333] cursor-pointer transition duration-300 ease-in text-blue-500 hover:bg-blue-600 hover:text-white max-sm:w-[30px] max-sm:h-[30px]'
            ><FaTwitter className='text-xl'/></Link>
        </div>
        <div>
            <Link
                to={webData?.fiverr_link}
                target='_blank'
                className='w-[40px] h-[40px] flex justify-center items-center rounded-md bg-[#333] cursor-pointer transition duration-300 ease-in text-blue-500 hover:bg-blue-600 hover:text-white max-sm:w-[30px] max-sm:h-[30px]'
            ><TbBrandFiverr className='text-xl'/></Link>
        </div>
        <div>
            <Link
                to={webData?.upwork_link}
                target='_blank'
                className='w-[40px] h-[40px] flex justify-center items-center rounded-md bg-[#333] cursor-pointer transition duration-300 ease-in text-blue-500 hover:bg-blue-600 hover:text-white max-sm:w-[30px] max-sm:h-[30px]'
            ><FaUpwork className='text-xl'/></Link>
        </div>
    </div>
  )
}

export default SocialMedia